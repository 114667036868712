import types from "./types";
import apiUrls from "../../constants/apiUrls";
import Storage from "../../utils/helpers/Storage";
import { UPLOAD_FILES_TYPES } from "../../constants/common";
import { uiAction } from "../ui";

const uploadFile = (file, attr) => (dispatch) => {
  const data = new FormData();
  data.append("file", file);
  data.append("attr", attr);
  let actionType = "";
  switch (attr) {
    case UPLOAD_FILES_TYPES.VIDEO:
      actionType = types.SEND_VIDEO;
      break;
    case UPLOAD_FILES_TYPES.LOGO:
      actionType = types.SEND_LOGO;
      break;
    case UPLOAD_FILES_TYPES.CSV:
      actionType = types.SEND_CSV;
      break;
    default:
      console.error("Unknown action file type");
  }
  dispatch(uiAction.requestLoading(true));
  dispatch({
    type: actionType,
    payload: {
      request: {
        url: apiUrls.files,
        method: "POST",
        data,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch({
            type: types.SET_UPLOAD_PROGRESS,
            payload: percentCompleted,
          });
        },
      },
    },
  });
  dispatch(uiAction.requestLoading(false));
};

const createVideoPageTemplate = (data) => {
  return {
    type: types.CREATE_VIDEO_PAGE,
    payload: {
      request: { url: apiUrls.videoPageTemplates, method: "POST", data },
    },
  };
};

const createVideoTemplate = (data) => {
  return {
    type: types.CREATE_VIDEO,
    payload: {
      request: { url: apiUrls.videoTemplate, method: "POST", data },
    },
  };
};

const getLandingPageURL = (id) => {
  return {
    type: types.GET_LANDING_PAGE_URL,
    payload: {
      request: {
        url: `${apiUrls.videoPageTemplates}/${id}/csv-urls`,
        method: "GET",
      },
    },
  };
};

const getVideoPageURL = (id) => {
  return {
    type: types.GET_LANDING_PAGE_URL,
    payload: {
      request: {
        url: `${apiUrls.videoTemplate}/${id}`,
        method: "GET",
      },
    },
  };
};

const getVideoPagePreview = (previewData) => {
  const token = Storage.get(process.env.REACT_APP_TOKEN_KEY);
  return {
    type: types.GET_VIDEO_PAGE_PREVIEW,
    payload: {
      request: {
        url: `${apiUrls.previewVideoPageTemplate}`,
        method: "GET",
        params: { ...previewData, token },
      },
    },
  };
};

const getVideoPageTemplate = (id) => {
  return {
    type: types.GET_LANDING_PAGE_URL,
    payload: {
      request: {
        url: `${apiUrls.videoPageTemplates}/${id}`,
        method: "GET",
      },
    },
  };
};

const deleteFile = (fileType) => ({
  type: types.DELETE_FILE,
  payload: fileType,
});

const setCreatePageData = (formData) => ({
  type: types.SET_CREATE_PAGE_DATA,
  payload: formData,
});

export {
  deleteFile,
  uploadFile,
  createVideoPageTemplate,
  getLandingPageURL,
  getVideoPageTemplate,
  createVideoTemplate,
  getVideoPageURL,
  setCreatePageData,
  getVideoPagePreview,
};
