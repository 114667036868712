export default {
  LOGOUT: "LOGOUT",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_REQUEST_SUCCESS: "LOGIN_REQUEST_SUCCESS",
  LOGIN_REQUEST_FAIL: "LOGIN_REQUEST_FAIL",

  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_REQUEST_SUCCESS: "REGISTER_REQUEST_SUCCESS",
  REGISTER_REQUEST_FAIL: "REGISTER_REQUEST_FAIL",

  GET_CURRENT_USER: "GET_CURRENT_USER",
  GET_CURRENT_USER_SUCCESS: "GET_CURRENT_USER_SUCCESS",
  GET_CURRENT_USER_FAIL: "GET_CURRENT_USER_FAIL",

  USER_UPDATE_FIRST_AUTH: "USER_UPDATE_FIRST_AUTH",
  USER_UPDATE_FIRST_AUTH_SUCCESS: "USER_UPDATE_FIRST_AUTH_SUCCESS",
  USER_UPDATE_FIRST_AUTH_FAIL: "USER_UPDATE_FIRST_AUTH_FAIL",
};
