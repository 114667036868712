import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { ROUTES } from "../../constants/routes";
import { START_ROUTE_LINK } from "../../constants/common";
import { Loader } from "../../components";

const locationHelper = locationHelperBuilder();

const isAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || ROUTES.LOGIN,
  authenticatedSelector: ({ user: { token } }) => !!token,
  authenticatingSelector: ({ ui: { requestLoading } }) => !!requestLoading,
  AuthenticatingComponent: Loader,
  allowRedirectBack: false,
  wrapperDisplayName: "isAuthenticated",
});

const noAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || START_ROUTE_LINK,
  authenticatedSelector: ({ user: { token } }) => !token,
  authenticatingSelector: ({ ui: { requestLoading } }) => !!requestLoading,
  AuthenticatingComponent: Loader,
  allowRedirectBack: false,
  wrapperDisplayName: "noAuthenticated",
});

export { isAuthenticated, noAuthenticated };
