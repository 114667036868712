import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Icon from "../icon";
import "./style.scss";
import { ICONS_NAMES } from "../../constants/common";

const ToggledBlock = ({ isOpen, children, className, onClose }) => {
  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
    if (onClose && typeof onClose === "function") {
      onClose();
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  if (!open) return null;

  return (
    <div className={`ToggledBlock ${className}`}>
      <Icon
        onClick={handleClose}
        name={ICONS_NAMES.CROSS_CIRCLE}
        className="ToggledBlock_close"
        size={84}
      />
      {children}
    </div>
  );
};

ToggledBlock.defaultProps = { isOpen: false, className: "", onClose: null };
ToggledBlock.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default ToggledBlock;
