import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { campaignsOperation } from "../../store/campaigns";
import {
  CAMPAIGN_STEPS_VALUE,
  CAMPAIGN_VIDEO_TYPES,
} from "../../constants/common";
import { CampaignModel } from "../../utils/helpers/defPropTypes";
import "./style.scss";

const VideoSelectTypeScreen = ({ campaign }) => {
  const dispatch = useDispatch();
  const loading = useSelector(({ ui: { requestLoading } }) => requestLoading);

  const setCampaignEach = () => {
    if (campaign) {
      dispatch(
        campaignsOperation.updateCampaignRequest({
          id: campaign.id,
          step: CAMPAIGN_STEPS_VALUE.VIDEOS,
          video_type: CAMPAIGN_VIDEO_TYPES.each,
        })
      );
    }
  };

  const setCampaignGeneric = () => {
    if (campaign) {
      dispatch(
        campaignsOperation.updateCampaignRequest({
          id: campaign.id,
          step: CAMPAIGN_STEPS_VALUE.VIDEOS,
          video_type: CAMPAIGN_VIDEO_TYPES.generic,
        })
      );
    }
  };

  return (
    <div className="VideoSelectTypeScreen">
      <p className="pageSubTitle m-b-25">
        Choose from one of the following options
      </p>

      <div className="VideoSelectTypeScreen_wrap">
        <button className="btn" onClick={setCampaignEach} disabled={loading}>
          Record/upload a video for each lead
        </button>
        <p className="danger">
          For each record/upload the leads in your list will be shown and then
          saved.
        </p>
        <button
          className="btn btn-blue"
          onClick={setCampaignGeneric}
          disabled={loading}
        >
          Record/upload a generic video
        </button>
        <p className="danger">
          Record/upload one single video for all leads and personalize with an
          overlay optionally.
        </p>
      </div>
    </div>
  );
};

VideoSelectTypeScreen.propTypes = {
  campaign: PropTypes.shape(CampaignModel).isRequired,
};

export default VideoSelectTypeScreen;
