import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import ui from "./ui";
import user from "./user";
import files from "./files";
import campaigns from "./campaigns";

const uiPersistConfig = {
  key: "ui",
  storage,
  blacklist: [
    "error",
    "voiceTrainingCompletedSteps",
    "requestLoading",
    "menus",
    "sentences",
    "countdown",
  ],
};
const userPersistConfig = { key: "user", storage, blacklist: ["user"] };
const campaignsPersistConfig = {
  key: "campaigns",
  storage,
  blacklist: ["current", "leads", "data"],
};

const rootReducer = combineReducers({
  ui: persistReducer(uiPersistConfig, ui),
  user: persistReducer(userPersistConfig, user),
  campaigns: persistReducer(campaignsPersistConfig, campaigns),
  files,
});

export default rootReducer;
