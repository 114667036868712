import { lazy } from "react";
import { Page404, InfoPages } from "../pages";

const CampaignsLazy = lazy(() => import("../pages/main/сampaigns"));
const CampaignLazy = lazy(() => import("../pages/main/campaign"));

export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  DASHBOARD: "/dashboard",
  SUPPORT: "/support",
  CONTACT: "/contact",
  TERMS: "/terms",
  T_C: "t-c",
  MAIN: [
    {
      uid: "campaigns-pages",
      link: "/campaigns",
      component: CampaignsLazy,
      exact: true,
    },
    {
      uid: "campaign-page-edit",
      link: "/campaigns/:campaignId?",
      component: CampaignLazy,
      exact: false,
    },
    {
      uid: "landing-pages",
      link: "/landing-pages",
      component: Page404,
      exact: true,
    },
    {
      uid: "videos",
      link: "/videos",
      component: Page404,
      exact: true,
    },
    {
      uid: "integrations",
      link: "/integrations",
      component: Page404,
      exact: true,
    },
    {
      uid: "reports",
      link: "/reports",
      component: Page404,
      exact: true,
    },
    {
      uid: "support",
      link: "/support",
      component: InfoPages.SupportPage,
      exact: true,
    },
  ],
};
