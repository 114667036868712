import * as yup from "yup";

export const signInValidator = yup.object().shape({
  email: yup.string().email("Invalid email").required("Required"),
  password: yup
    .string()
    .min(4, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
});
