import React from "react";
import PropTypes from "prop-types";
import { LeadModel } from "../../utils/helpers/defPropTypes";
import "./style.scss";

const CampaignVideosLeadSteps = ({ className, leads, active, index }) => {
  return (
    <div className={`CampaignVideosLeadSteps ${className}`}>
      <p className="CampaignVideosLeadSteps_text m-b-15">
        {active && active.fields
          ? Object.values(active.fields).join(", ")
          : "None"}
      </p>
      <div className="CampaignVideosLeadSteps_steps">
        <div className="CampaignVideosLeadSteps_steps__line">
          <span style={{ width: `${(100 * index) / leads?.length}%` }} />
        </div>
        <p className="CampaignVideosLeadSteps_steps__count">
          <span>Lead </span>
          <span>
            {index}/{leads?.length}
          </span>
        </p>
      </div>
    </div>
  );
};

CampaignVideosLeadSteps.defaultProps = {
  className: "",
  active: null,
  leads: null,
  index: 1,
};
CampaignVideosLeadSteps.propTypes = {
  className: PropTypes.string,
  leads: PropTypes.arrayOf(LeadModel),
  active: LeadModel,
  index: PropTypes.number,
};

export default CampaignVideosLeadSteps;
