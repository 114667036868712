import types from "./types";
import apiUrls from "../../constants/apiUrls";

const getMenuRequest = () => ({
  type: types.GET_MENU,
  payload: {
    request: { url: apiUrls.menu, method: "GET" },
  },
});

export { getMenuRequest };
