import React from "react";
import { Form } from "formik";
import PropTypes from "prop-types";
import { AuthFooter, AuthHeader, Input } from "../../../components";
import { AuthForm } from "../../../containers";
import { ROUTES } from "../../../constants/routes";

const ForgotPassword = ({ history }) => {
  const forgotPassHandler = (values, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);
      history.push(ROUTES.LOGIN);
    }, 2000);
  };

  return (
    <div className="Auth">
      <AuthHeader buttonData={{ text: "Sign In", link: ROUTES.LOGIN }} />

      <div className="Auth_content">
        <AuthForm
          title="Forgot Password"
          onSubmit={forgotPassHandler}
          initialValues={{ password: "" }}
        >
          {({ errors, isSubmitting }) => (
            <Form>
              <Input
                className="m-b-20"
                type="password"
                name="password"
                error={errors.email}
                placeholder="Password"
              />

              <button
                className="btn btn-block m-b-10"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </button>
              <p className="Auth_content__link text-center">
                We’ll send you a link to reset your password
              </p>
            </Form>
          )}
        </AuthForm>
      </div>

      <AuthFooter />
    </div>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default ForgotPassword;
