import React from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import TextArea from "../textArea";
import { updateCampaignRequest } from "../../store/campaigns/operation";
import { CampaignModel } from "../../utils/helpers/defPropTypes";
import "./style.scss";
import { CAMPAIGN_STEPS_VALUE } from "../../constants/common";

const WelcomeMessageForm = ({ campaign }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      welcomeMessage:
        campaign?.welcome_text ||
        "Example:\n \n Hi, {{Firstname}}, this is a quick video\n",
    },
    onSubmit: ({ welcomeMessage }) => {
      dispatch(
        updateCampaignRequest({
          id: campaign.id,
          step: CAMPAIGN_STEPS_VALUE.VIDEOS,
          welcome_text: welcomeMessage,
        })
      );
    },
  });

  const setValueHandler = ({ target: { name, value } }) => {
    formik.setFieldValue(name, value, true);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row m-b-30 form-wrapper">
        <p className="text-center m-b-20">Add welcome message on 1st frame</p>
        <TextArea
          maxLength={100}
          id="welcomeMessage"
          error={formik.errors.welcomeMessage}
          defaultValue="Insert variable"
          name="welcomeMessage"
          onChange={setValueHandler}
          className="m-b-15"
          variables={campaign.fields}
          value={formik.values.welcomeMessage}
        />
      </div>
      <button
        disabled={campaign?.welcome_text === formik.values.welcomeMessage}
        className="btn btn-blue"
        type="submit"
      >
        Save welcome message
      </button>
    </form>
  );
};

WelcomeMessageForm.defaultProps = {};

WelcomeMessageForm.propTypes = {
  campaign: PropTypes.shape(CampaignModel).isRequired,
};

export default WelcomeMessageForm;
