import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { START_ROUTE_LINK } from "../constants/common";
import { Page404 } from "../pages";

const MainRoutes = () => (
  <Suspense fallback={<div>loading...</div>}>
    <Switch>
      {ROUTES.MAIN.map((route) => (
        <Route
          exact={route.exact}
          key={`router-${route.uid}`}
          component={route.component}
          path={route.link}
        />
      ))}

      <Redirect exact to={START_ROUTE_LINK} from="/" />
      <Route component={Page404} />
    </Switch>
  </Suspense>
);

export default MainRoutes;
