import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./style.scss";

const ConfirmModal = ({
  isOpen,
  children,
  closeModal,
  onSubmit,
  btnText,
  modalText,
}) => {
  useLayoutEffect(() => {
    document.body.style.overflow = isOpen.status ? "hidden" : "auto";
  }, [isOpen.status]);

  const submitHandler = () => {
    if (onSubmit && typeof onSubmit === "function") {
      onSubmit(isOpen);
    }
  };

  return (
    isOpen.status &&
    ReactDOM.createPortal(
      <div className="modal_container">
        <div className="modal_confirmation__body">
          <button onClick={closeModal} className="modal_confirmation_close">
            Close
          </button>
          {children || (
            <h3 className="modal_confirmation__body__title">{modalText}</h3>
          )}
          <div className="modal_confirmation__body__buttons_wrapper">
            <button className="btn btn-blue" onClick={submitHandler}>
              {btnText.yes}
            </button>
            <button className="btn btn-outline-blue" onClick={closeModal}>
              {btnText.no}
            </button>
          </div>
        </div>
      </div>,
      document.body
    )
  );
};

ConfirmModal.defaultProps = {
  isOpen: { status: false, data: null },
  onSubmit: null,
  btnText: {
    yes: "Yes",
    no: "No",
  },
  modalText: "",
  children: null,
};

ConfirmModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.shape({
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    data: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.bool,
    ]),
  }),
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  btnText: PropTypes.shape({
    yes: PropTypes.string.isRequired,
    no: PropTypes.string.isRequired,
  }),
  modalText: PropTypes.string,
};

export default ConfirmModal;
