import React from "react";
import PropTypes from "prop-types";
import { ICONS_NAMES } from "../../constants/common";

const Icon = ({ name, size, color, className, onClick }) => {
  switch (name) {
    case ICONS_NAMES.MICROPHONE: {
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 16.6 26"
          xmlSpace="preserve"
          width={size}
          height={size}
          fill={color}
          className={className}
        >
          <g>
            <g>
              <path
                d="M16.6,13.4c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9c0,3.6-2.9,6.5-6.5,6.5S1.8,17,1.8,13.4c0-0.5-0.4-0.9-0.9-0.9
          S0,12.9,0,13.4c0,4.3,3.2,7.8,7.4,8.3v2.4H4.1c-0.5,0-0.9,0.4-0.9,0.9S3.5,26,4.1,26h8.5c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9
          H9.2v-2.4C13.5,21.3,16.6,17.7,16.6,13.4z"
              />
            </g>
          </g>
          <g>
            <g>
              <path d="M8.3,0C5.5,0,3.2,2.3,3.2,5.1v8.3c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1V5.1C13.4,2.3,11.1,0,8.3,0z" />
            </g>
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.VOICE: {
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 30 30"
          xmlSpace="preserve"
          width={size}
          height={size}
          fill={color}
          className={className}
        >
          <path
            d="M22.1,14.1c-0.3,0.3-0.3,0.9,0,1.2c2.3,2.3,2.3,6.1,0,8.5c-0.3,0.3-0.3,0.9,0,1.2c0.2,0.2,0.4,0.3,0.6,0.3
	c0.2,0,0.4-0.1,0.6-0.3c3-3,3-7.9,0-10.9C23,13.7,22.4,13.7,22.1,14.1L22.1,14.1z"
          />
          <path
            d="M18.8,16.8c-0.3,0.3-0.3,0.9,0,1.2c0.8,0.8,0.8,2.2,0,3c-0.3,0.3-0.3,0.9,0,1.2c0.2,0.2,0.4,0.3,0.6,0.3s0.4-0.1,0.6-0.3
	c1.5-1.5,1.5-4,0-5.5C19.7,16.4,19.1,16.4,18.8,16.8L18.8,16.8z"
          />
          <path
            d="M25.4,12.7c3.8,3.8,3.8,9.9,0,13.7c-0.3,0.3-0.3,0.9,0,1.2c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3
	c4.5-4.5,4.5-11.7,0-16.2c-0.3-0.3-0.9-0.3-1.2,0C25.1,11.8,25.1,12.3,25.4,12.7L25.4,12.7z"
          />
          <path
            d="M0.9,0C0.4,0,0,0.4,0,0.9l0,28.2C0,29.6,0.4,30,0.9,30H3c0.3,0,0.7-0.2,0.8-0.5L5,26.9c0.2-0.4,0.6-0.7,1-0.7
	c1.6,0,3.5,0.1,4.5,0c1.1,0,1.7-0.5,2-0.8c0.5-0.5,0.7-1.2,0.6-2c0-0.4,0.1-0.6,0.3-0.9c0.2-0.2,0.4-0.5,0.5-0.9
	c0.2-0.6-0.1-1.3-0.6-1.7c0,0,0,0-0.1-0.1l-1.8-1.1l2.6-1c0,0,0,0,0.1,0c0.6-0.3,1-1,0.8-1.7l-0.3-1.8c0-0.2,0-0.4,0.1-0.5
	s0.3-0.2,0.5-0.3l1.7-0.3c0.5-0.1,0.9-0.4,1.1-0.8c0.2-0.4,0.2-0.9-0.1-1.4l-3.3-5.6c-0.5-0.9-0.7-1.9-0.6-2.8L14.3,1
	c0-0.3,0-0.5-0.2-0.7C14,0.1,13.7,0,13.5,0L0.9,0L0.9,0z"
          />
        </svg>
      );
    }
    case ICONS_NAMES.CROSS_CIRCLE: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          className={className}
          onClick={onClick}
          viewBox="0 0 42.426 42.426"
        >
          <g transform="translate(377.24 -489.47) rotate(90)">
            <g
              data-name="Ellipse 6"
              transform="translate(489.47 356.026) rotate(-45)"
              stroke="#393f4d"
              strokeWidth="1.5"
              fill="#fff"
            >
              <circle cx="15" cy="15" r="15" stroke="none" />
              <circle cx="15" cy="15" r="14.25" stroke={color} fill="none" />
            </g>
            <g data-name="delete" transform="translate(-3 1)">
              <line
                data-name="Linie 4"
                x2="21.213"
                transform="translate(505.916 347.334) rotate(45)"
                fill="none"
                stroke={color}
                strokeWidth="2"
              />
              <line
                data-name="Linie 5"
                x2="21.213"
                transform="translate(520.916 347.334) rotate(135)"
                fill="none"
                stroke={color}
                strokeWidth="2"
              />
            </g>
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.ARROW_RIGHT: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size * 2.5}
          height={size}
          className={className}
          onClick={onClick}
          viewBox="0 0 77.213 21.834"
        >
          <g data-name="Gruppe 105" transform="translate(77.213) rotate(90)">
            <path
              data-name="Pfad 365"
              d="M13.605.783a1.591,1.591,0,0,0-2.741,0C6.427,8.875,2.38,17.1.031,25.974-.23,27.279,1.206,28.584,2.38,27.8a29.838,29.838,0,0,0,6.787-6.657c-.522,18.273-3,37.2,1.305,55.211.261,1.305,2.48,1.044,2.349-.261-2.349-18.665-.522-37.2-.261-55.863C14.78,22.188,17,24.146,19.087,26.1a1.606,1.606,0,0,0,2.741-1.175A63.206,63.206,0,0,0,13.605.783ZM12.17,16.315a1.64,1.64,0,0,0-3,.914v.261c-1.566,1.566-3,3.133-4.7,4.7a119.645,119.645,0,0,1,7.7-17.229,57.872,57.872,0,0,1,5.612,15.924c-1.436-1.305-3-2.61-4.438-4.046A.965.965,0,0,0,12.17,16.315Z"
              transform="translate(0 0)"
              fill={color}
            />
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.RECORD: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 70 70"
          className={className}
          onClick={onClick}
        >
          <g transform="translate(-335 -414)">
            <g
              data-name="Rechteck 132"
              transform="translate(335 414)"
              stroke={color}
              strokeWidth="3"
            >
              <rect width="70" height="70" rx="15" fill="none" stroke="none" />
              <rect
                x="1.5"
                y="1.5"
                width="67"
                height="67"
                rx="13.5"
                fill="none"
              />
            </g>
            <g data-name="Gruppe 174" transform="translate(358 437)">
              <g data-name="Gruppe 173">
                <g data-name="Gruppe 172">
                  <path
                    d="M12.5,6.25a6.25,6.25,0,1,0,6.25,6.25A6.252,6.252,0,0,0,12.5,6.25Z"
                    fill={color}
                  />
                  <path
                    d="M12.5,0A12.5,12.5,0,1,0,25,12.5,12.5,12.5,0,0,0,12.5,0Zm0,22.5a10,10,0,1,1,10-10A10,10,0,0,1,12.5,22.5Z"
                    fill={color}
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.LANDING_PAGES: {
      return (
        <svg
          data-name="Gruppe 144"
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          onClick={onClick}
          className={className}
          viewBox="0 0 25 21.777"
        >
          <path
            data-name="Pfad 77"
            d="M24.268,0H20.6V4.395H25V.732A.732.732,0,0,0,24.268,0Z"
            fill={color}
          />
          <path
            data-name="Pfad 78"
            d="M19.141,0H.732A.732.732,0,0,0,0,.732V4.395H19.141Z"
            fill={color}
          />
          <path
            data-name="Pfad 79"
            d="M5.371,10.889H9.766v4.394H5.371Z"
            fill={color}
          />
          <path
            data-name="Pfad 80"
            d="M0,21.045a.732.732,0,0,0,.732.732H24.268A.732.732,0,0,0,25,21.045V5.859H0ZM17.627,9.424h2.734a.733.733,0,1,1,0,1.465H17.627a.733.733,0,0,1,0-1.465Zm-1.465,2.93h4.2a.732.732,0,1,1,0,1.464h-4.2a.732.732,0,1,1,0-1.464Zm0,2.929h4.2a.732.732,0,1,1,0,1.465h-4.2a.732.732,0,0,1,0-1.465ZM3.906,10.156a.733.733,0,0,1,.733-.732H10.5a.732.732,0,0,1,.732.732v5.86a.732.732,0,0,1-.732.732H4.639a.733.733,0,0,1-.733-.732Z"
            fill={color}
          />
        </svg>
      );
    }
    case ICONS_NAMES.CAMERA: {
      return (
        <svg
          version="1.1"
          width={size}
          height={size}
          className={className}
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 25 14.9"
          style={{ enableBackground: "new 0 0 25 14.9" }}
          xmlSpace="preserve"
          fill={color}
        >
          <path d="M16.1,0H1.7C0.8,0,0,0.8,0,1.7v11.4c0,1,0.8,1.7,1.7,1.7h14.4c1,0,1.7-0.8,1.7-1.7V1.7C17.9,0.8,17.1,0,16.1,0z" />
          <path d="M19,9.8l6,3.3V1.9l-6,3.3V9.8z" />
        </svg>
      );
    }
    case ICONS_NAMES.VIDEO_LIBRARY: {
      return (
        <svg
          data-name="Gruppe 198"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          onClick={onClick}
          width={size}
          height={size}
          viewBox="0 0 25 25"
        >
          <g data-name="Gruppe 139">
            <g data-name="Gruppe 138">
              <path
                d="M203.334,205.833h-2.5v17.5a2.5,2.5,0,0,0,2.5,2.5h17.5v-2.5h-17.5Z"
                transform="translate(-200.833 -200.833)"
                fill={color}
              />
              <path
                d="M223.333,200.833h-15a2.5,2.5,0,0,0-2.5,2.5v15a2.5,2.5,0,0,0,2.5,2.5h15a2.5,2.5,0,0,0,2.5-2.5v-15A2.5,2.5,0,0,0,223.333,200.833Zm-10,15.626v-11.25l7.5,5.625Z"
                transform="translate(-200.833 -200.833)"
                fill={color}
              />
            </g>
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.LANDING_LIBRARY: {
      return (
        <svg
          enableBackground="new 0 0 513.124 513.124"
          fill={color}
          height={size}
          widths={size}
          className={className}
          onClick={onClick}
          viewBox="0 0 513.124 513.124"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path d="m503.685 29.49h-64.92c-5.213 0-9.439 4.326-9.439 9.663v27.841h83.799v-27.842c-.001-5.336-4.227-9.662-9.44-9.662z" />
            <path d="m385.852 75.652h-64.919c-5.213 0-9.439 4.326-9.439 9.663v48.474h83.798v-48.474c-.001-5.337-4.227-9.663-9.44-9.663z" />
            <path d="m429.325 101.832h83.799v338.833h-83.799z" />
            <path d="m311.493 168.628h83.798v343.63h-83.798z" />
            <path d="m429.325 475.504h83.799v36.754h-83.799z" />
            <path d="m97.647 97.062h-88.208c-5.213 0-9.439 4.326-9.439 9.663v36.444h107.086v-36.444c0-5.337-4.226-9.663-9.439-9.663z" />
            <path d="m0 178.007h107.086v334.251h-107.086z" />
            <path d="m141.12 431.634h136.34v80.624h-136.34z" />
            <path d="m141.12 116.749h136.34v280.047h-136.34z" />
            <path d="m268.021.867h-117.462c-5.213 0-9.439 4.326-9.439 9.663v71.381h136.34v-71.382c0-5.336-4.226-9.662-9.439-9.662z" />
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.STATICS: {
      return (
        <svg
          width={size}
          height={size}
          fill={color}
          className={className}
          onClick={onClick}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 25 25"
          enableBackground="new 0 0 25 25"
          xmlSpace="preserve"
        >
          <path
            d="M3.7,11.7c0.6,0,1.1-0.2,1.5-0.6l2.2,1.1c0,0.1,0,0.2,0,0.3c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2c0-0.3-0.1-0.7-0.2-0.9
	l2.9-2.9c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.2-1,2.2-2.2c0-0.2,0-0.4-0.1-0.7L20.1,4c0.3,0.2,0.8,0.4,1.2,0.4c1.2,0,2.2-1,2.2-2.2
	S22.5,0,21.3,0s-2.2,1-2.2,2.2c0,0.2,0,0.4,0.1,0.7l-2.6,1.9c-0.3-0.2-0.8-0.4-1.2-0.4c-1.2,0-2.2,1-2.2,2.2c0,0.3,0.1,0.7,0.2,0.9
	l-2.9,2.9c-0.3-0.1-0.6-0.2-0.9-0.2c-0.6,0-1.1,0.2-1.5,0.6L5.9,9.8c0-0.1,0-0.2,0-0.3c0-1.2-1-2.2-2.2-2.2s-2.2,1-2.2,2.2
	S2.5,11.7,3.7,11.7z"
          />
          <path
            d="M24.3,23.5h-0.8V8.1c0-0.4-0.3-0.7-0.7-0.7h-2.9c-0.4,0-0.7,0.3-0.7,0.7v15.5h-1.5V12.5c0-0.4-0.3-0.7-0.7-0.7H14
	c-0.4,0-0.7,0.3-0.7,0.7v11.1h-1.5v-5.2c0-0.4-0.3-0.7-0.7-0.7H8.1c-0.4,0-0.7,0.3-0.7,0.7v5.2H5.9v-8.2c0-0.4-0.3-0.7-0.7-0.7H2.2
	c-0.4,0-0.7,0.3-0.7,0.7v8.2H0.7c-0.4,0-0.7,0.3-0.7,0.7S0.3,25,0.7,25h23.5c0.4,0,0.7-0.3,0.7-0.7S24.7,23.5,24.3,23.5z"
          />
        </svg>
      );
    }
    case ICONS_NAMES.INTEGRATE: {
      return (
        <svg
          version="1.1"
          width={size}
          height={size}
          fill={color}
          className={className}
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 25 24"
          enableBackground="new 0 0 25 24"
          xmlSpace="preserve"
        >
          <g>
            <g>
              <g>
                <path
                  d="M9.3,8L7.8,6.6C8,6.2,8.1,5.9,8.1,5.5c0-1.2-1-2.3-2.3-2.3s-2.3,1-2.3,2.3s1,2.3,2.3,2.3c0.4,0,0.8-0.1,1.2-0.3l1.5,1.4
				C8.8,8.5,9.1,8.2,9.3,8z"
                />
                <path
                  d="M15.8,7.8l2-3.4c0.2,0.1,0.4,0.1,0.6,0.1c1.2,0,2.3-1,2.3-2.3c0-1.2-1-2.3-2.3-2.3c-1.2,0-2.3,1-2.3,2.3
				c0,0.6,0.2,1.2,0.7,1.6l-2,3.4C15.1,7.4,15.5,7.6,15.8,7.8z"
                />
                <path
                  d="M22.7,10.7c-1,0-1.8,0.6-2.1,1.5l-2.8-0.3c0,0,0,0,0,0.1c0,0.4,0,0.7-0.1,1.1l2.8,0.3c0.2,1.1,1.1,1.9,2.2,1.9
				c1.2,0,2.3-1,2.3-2.3C25,11.7,24,10.7,22.7,10.7z"
                />
                <path
                  d="M15,19.5l-0.6-2.7C14,16.9,13.6,17,13.3,17l0.6,2.7c-0.7,0.4-1.2,1.1-1.2,2c0,1.2,1,2.3,2.3,2.3c1.2,0,2.3-1,2.3-2.3
				C17.2,20.6,16.2,19.6,15,19.5z"
                />
                <path
                  d="M7.6,13l-3.5,1.2c-0.4-0.6-1.1-1-1.9-1c-1.2,0-2.3,1-2.3,2.3c0,1.2,1,2.3,2.3,2.3c1.2,0,2.3-1,2.3-2.3c0-0.1,0-0.1,0-0.2
				L8,14.1C7.8,13.7,7.7,13.4,7.6,13z"
                />
                <circle cx="12.7" cy="11.9" r="3.9" />
              </g>
            </g>
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.PLAY: {
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClick}
          className={className}
          width={size}
          height={size}
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 30.051 30.051"
          enableBackground="new 0 0 30.051 30.051;"
          xmlSpace="preserve"
        >
          <g fill={color}>
            <path
              d="M19.982,14.438l-6.24-4.536c-0.229-0.166-0.533-0.191-0.784-0.062c-0.253,0.128-0.411,0.388-0.411,0.669v9.069
		c0,0.284,0.158,0.543,0.411,0.671c0.107,0.054,0.224,0.081,0.342,0.081c0.154,0,0.31-0.049,0.442-0.146l6.24-4.532
		c0.197-0.145,0.312-0.369,0.312-0.607C20.295,14.803,20.177,14.58,19.982,14.438z"
            />
            <path
              d="M15.026,0.002C6.726,0.002,0,6.728,0,15.028c0,8.297,6.726,15.021,15.026,15.021c8.298,0,15.025-6.725,15.025-15.021
		C30.052,6.728,23.324,0.002,15.026,0.002z M15.026,27.542c-6.912,0-12.516-5.601-12.516-12.514c0-6.91,5.604-12.518,12.516-12.518
		c6.911,0,12.514,5.607,12.514,12.518C27.541,21.941,21.937,27.542,15.026,27.542z"
            />
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.LOGOUT: {
      return (
        <svg
          onClick={onClick}
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size + 7}
          viewBox="0 0 29 37.5"
        >
          <g transform="translate(-1729.036 -113)">
            <g
              transform="translate(1729.036 113)"
              fill="none"
              stroke={color}
              strokeWidth="5"
            >
              <circle cx="14.5" cy="14.5" r="14.5" stroke="none" />
              <circle cx="14.5" cy="14.5" r="12" fill="none" />
            </g>
            <line
              y2="17.36"
              transform="translate(1743.287 130.64)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="5"
            />
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.MORE: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          onClick={onClick}
          width={size}
          height={size / 2.2}
          viewBox="0 0 30 8"
        >
          <g transform="translate(-316 -52)">
            <g
              transform="translate(316 52)"
              fill={color}
              stroke={color}
              strokeWidth="1"
            >
              <circle cx="4" cy="4" r="4" stroke="none" />
              <circle cx="4" cy="4" r="3.5" fill="none" />
            </g>
            <g
              transform="translate(327 52)"
              fill={color}
              stroke={color}
              strokeWidth="1"
            >
              <circle cx="4" cy="4" r="4" stroke="none" />
              <circle cx="4" cy="4" r="3.5" fill="none" />
            </g>
            <g
              transform="translate(338 52)"
              fill={color}
              stroke={color}
              strokeWidth="1"
            >
              <circle cx="4" cy="4" r="4" stroke="none" />
              <circle cx="4" cy="4" r="3.5" fill="none" />
            </g>
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.PROFILE: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          onClick={onClick}
          width={size}
          height={size}
          viewBox="0 0 32.231 35.161"
        >
          <path
            d="M783.655,782.207a5.7,5.7,0,0,1-1.671,4.338,6.173,6.173,0,0,1-4.441,1.591H757.536a6.173,6.173,0,0,1-4.441-1.591,5.7,5.7,0,0,1-1.671-4.338q0-1.213.08-2.369a23.331,23.331,0,0,1,.32-2.5,19.4,19.4,0,0,1,.607-2.484,11.757,11.757,0,0,1,.984-2.232,8.049,8.049,0,0,1,1.419-1.854,5.974,5.974,0,0,1,1.957-1.225,6.874,6.874,0,0,1,2.552-.458,2.794,2.794,0,0,1,.961.492q.755.492,1.706,1.1a10.814,10.814,0,0,0,2.472,1.1,9.734,9.734,0,0,0,6.112,0,10.819,10.819,0,0,0,2.472-1.1q.95-.607,1.705-1.1a2.8,2.8,0,0,1,.961-.492,6.874,6.874,0,0,1,2.552.458,5.974,5.974,0,0,1,1.957,1.225,8.048,8.048,0,0,1,1.419,1.854,11.768,11.768,0,0,1,.984,2.232,19.4,19.4,0,0,1,.607,2.484,23.285,23.285,0,0,1,.32,2.5Q783.655,780.993,783.655,782.207Zm-7.325-20.442a8.792,8.792,0,1,1-2.575-6.215A8.469,8.469,0,0,1,776.33,761.764Z"
            transform="translate(-751.424 -752.974)"
            fill={color}
          />
        </svg>
      );
    }
    case ICONS_NAMES.SUPPORT: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          onClick={onClick}
          width={size}
          height={size}
          viewBox="0 0 33.121 33.121"
        >
          <g transform="translate(-225 -225)">
            <path
              d="M235.65,247.85a9.057,9.057,0,0,1-3.5-3.5L227.5,249l3.5,3.5Z"
              transform="translate(0 3.121)"
              fill={color}
            />
            <path
              d="M251,247.5l-3.15-3.15a9.057,9.057,0,0,1-3.5,3.5L249,252.5l3.5-3.5Z"
              transform="translate(3.121 3.121)"
              fill={color}
            />
            <path
              d="M234.8,247.85l-2.4,3.25a7.878,7.878,0,0,0,11.25,0l-2.4-3.25A5.061,5.061,0,0,1,234.8,247.85Z"
              transform="translate(3.573 3.121)"
              fill={color}
            />
            <path
              d="M241.2,232.15l2.4-3.25a7.878,7.878,0,0,0-11.25,0l2.4,3.25A5.061,5.061,0,0,1,241.2,232.15Z"
              transform="translate(3.548)"
              fill={color}
            />
            <path
              d="M252.5,231l-3.5-3.5-4.6,4.6a9.627,9.627,0,0,1,3.45,3.35Z"
              transform="translate(3.121)"
              fill={color}
            />
            <path
              d="M251.15,232.35a7.878,7.878,0,0,1,0,11.25l-.15-.074-3.15-2.324a5.345,5.345,0,0,0,1.15-3.209v-.738l-1-2.582Z"
              transform="translate(3.121 3.548)"
              fill={color}
            />
            <path
              d="M232.2,235.5a8.892,8.892,0,0,1,3.45-3.35L231,227.5l-3.5,3.5Z"
              fill={color}
            />
            <path
              d="M231,237.993a5.185,5.185,0,0,0,1.15,3.209l-3.25,2.4a7.878,7.878,0,0,1,0-11.25l3.1,2.324Z"
              transform="translate(0 3.548)"
              fill={color}
            />
            <path
              d="M255.858,231.79a.6.6,0,0,0-.11-.552l-3.864-3.864a.439.439,0,0,0-.552-.11A3.314,3.314,0,0,0,248.185,225H234.936a3.314,3.314,0,0,0-3.147,2.263.6.6,0,0,0-.552.11l-3.864,3.864a.439.439,0,0,0-.11.552A3.314,3.314,0,0,0,225,234.936v13.248a3.314,3.314,0,0,0,2.263,3.147.6.6,0,0,0,.11.552l3.864,3.864a.439.439,0,0,0,.552.11,3.335,3.335,0,0,0,3.147,2.263h13.248a3.314,3.314,0,0,0,3.147-2.263.6.6,0,0,0,.552-.11l3.864-3.864a.439.439,0,0,0,.11-.552,3.335,3.335,0,0,0,2.263-3.147V234.936A3.314,3.314,0,0,0,255.858,231.79Zm-1.27-.166-4.251,4.251a11.761,11.761,0,0,0-3.091-3.091l4.251-4.251Zm-3.643,14.629a10.523,10.523,0,0,0,0-9.44L253.76,234a14.36,14.36,0,0,1,0,15.125Zm-9.384,5.8a10.479,10.479,0,0,0,4.692-1.1l2.815,2.815a14.36,14.36,0,0,1-15.125,0l2.815-2.815A11.313,11.313,0,0,0,241.561,252.049Zm0-1.1a9.384,9.384,0,1,1,9.384-9.384A9.376,9.376,0,0,1,241.561,250.945Zm-12.2-1.822a14.36,14.36,0,0,1,0-15.125l2.815,2.815a10.523,10.523,0,0,0,0,9.44Zm12.2-18.051a10.479,10.479,0,0,0-4.692,1.1l-2.815-2.815a14.36,14.36,0,0,1,15.125,0l-2.926,2.815A10.479,10.479,0,0,0,241.561,231.072Zm-6.624-4.968h13.248a2.218,2.218,0,0,1,2.208,1.987l-.442.442a15.538,15.538,0,0,0-16.726,0l-.5-.442A2.218,2.218,0,0,1,234.936,226.1Zm-3.312,2.429,4.251,4.251a11.761,11.761,0,0,0-3.091,3.091l-4.251-4.251Zm-5.52,19.652V234.936a2.218,2.218,0,0,1,1.987-2.208l.442.442a15.538,15.538,0,0,0,0,16.726l-.442.5A2.218,2.218,0,0,1,226.1,248.185Zm2.429,3.312,4.251-4.251a11.761,11.761,0,0,0,3.091,3.091l-4.251,4.251Zm19.652,5.52H234.936a2.218,2.218,0,0,1-2.208-1.987l.442-.442a15.538,15.538,0,0,0,16.726,0l.442.442A2.126,2.126,0,0,1,248.185,257.017Zm3.312-2.429-4.251-4.251a11.761,11.761,0,0,0,3.091-3.091l2.981,2.981.166.166,1.1,1.1Zm5.52-13.028v6.624a2.218,2.218,0,0,1-1.987,2.208l-.442-.442a15.538,15.538,0,0,0,0-16.726l.442-.442a2.218,2.218,0,0,1,1.987,2.208Z"
              fill={color}
            />
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.SETTINGS: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          onClick={onClick}
          width={size}
          height={size}
          viewBox="0 0 35.582 35.584"
        >
          <path
            d="M55.819,36.256a.824.824,0,0,0-.82-.818H53.5a1.162,1.162,0,0,1-1.055-.784L50.4,29.886a1.149,1.149,0,0,1,.172-1.291l1.015-1.014a.822.822,0,0,0,0-1.158l-2.443-2.444a.828.828,0,0,0-1.167,0l-1.1,1.1a1.19,1.19,0,0,1-1.307.2l-4.149-1.657a1.154,1.154,0,0,1-.789-1.044V21.043a.819.819,0,0,0-.815-.817H36.359a.819.819,0,0,0-.817.817V22.58a1.187,1.187,0,0,1-.782,1.058L29.949,25.7a1.117,1.117,0,0,1-1.282-.167l-1.079-1.079a.824.824,0,0,0-1.157,0L23.986,26.9a.814.814,0,0,0,0,1.159l1.173,1.179a1.175,1.175,0,0,1,.2,1.3l-1.634,4.111a1.143,1.143,0,0,1-1.043.789H21.051a.82.82,0,0,0-.814.818v3.46a.822.822,0,0,0,.814.821h1.633a1.171,1.171,0,0,1,1.056.782l2.006,4.731a1.128,1.128,0,0,1-.159,1.287l-1.126,1.118a.821.821,0,0,0,0,1.159l2.447,2.445a.82.82,0,0,0,1.154,0l1.2-1.2a1.164,1.164,0,0,1,1.3-.19l4.2,1.674a1.158,1.158,0,0,1,.782,1.043v1.6a.821.821,0,0,0,.817.818h3.463a.821.821,0,0,0,.815-.818v-1.6a1.17,1.17,0,0,1,.789-1.052l4.692-1.991a1.143,1.143,0,0,1,1.286.174l1.058,1.063a.825.825,0,0,0,1.159,0l2.446-2.448a.818.818,0,0,0,0-1.158l-1.13-1.124a1.193,1.193,0,0,1-.189-1.3l1.7-4.231a1.152,1.152,0,0,1,1.049-.788H55a.826.826,0,0,0,.82-.818v-3.46ZM38.03,43.836a5.817,5.817,0,1,1,5.817-5.819A5.816,5.816,0,0,1,38.03,43.836Z"
            transform="translate(-20.237 -20.226)"
            fill={color}
          />
        </svg>
      );
    }
    case ICONS_NAMES.BILLING: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          onClick={onClick}
          width={size}
          height={size - 6.5}
          viewBox="0 0 30 23.63"
        >
          <g transform="translate(-0.292 -2.763)">
            <path
              d="M27.792,2.763a2.431,2.431,0,0,1,1.766.722,2.352,2.352,0,0,1,.734,1.739V23.932a2.347,2.347,0,0,1-.734,1.738,2.427,2.427,0,0,1-1.766.724h-25a2.422,2.422,0,0,1-1.765-.724,2.348,2.348,0,0,1-.735-1.738V5.224a2.353,2.353,0,0,1,.735-1.739,2.426,2.426,0,0,1,1.765-.722Zm-25,1.969a.479.479,0,0,0-.351.146.469.469,0,0,0-.149.346V8.671h26V5.224a.468.468,0,0,0-.147-.346.484.484,0,0,0-.352-.146Zm25,19.692a.489.489,0,0,0,.352-.146.468.468,0,0,0,.147-.346V14.578h-26v9.354a.469.469,0,0,0,.149.346.484.484,0,0,0,.351.146Zm-23.5-1.969V20.486h4v1.969Zm6,0V20.486h6v1.969Z"
              transform="translate(0)"
              fill={color}
            />
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.TEAM_MEMBERS: {
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="12 12 24 24"
          xmlSpace="preserve"
          width={size}
          height={size}
          className={className}
          onClick={onClick}
        >
          <path
            fill={color}
            d="M31.2,22.6c0,0.6-0.2,1.2-0.6,1.7c-0.5,0.4-1.1,0.6-1.7,0.6h-7.8c-0.6,0-1.3-0.2-1.7-0.6
	c-0.4-0.4-0.7-1.1-0.6-1.7c0-0.3,0-0.6,0-0.9c0-0.3,0.1-0.6,0.1-1c0.1-0.3,0.1-0.6,0.2-1c0.1-0.3,0.2-0.6,0.4-0.9
	c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.7-0.2,1-0.2c0.1,0,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.3,0.7,0.4
	c0.3,0.2,0.6,0.3,1,0.4c0.8,0.3,1.6,0.3,2.4,0c0.3-0.1,0.7-0.2,1-0.4c0.2-0.2,0.5-0.3,0.7-0.4c0.1-0.1,0.2-0.2,0.4-0.2
	c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.8,0.5c0.2,0.2,0.4,0.5,0.5,0.7c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.2,0.6,0.2,1
	c0.1,0.3,0.1,0.6,0.1,1C31.2,22,31.2,22.3,31.2,22.6z M28.4,14.7c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4
	c0-1.9,1.5-3.4,3.4-3.4c0.9,0,1.8,0.4,2.4,1C28.1,12.9,28.4,13.8,28.4,14.7L28.4,14.7z"
          />
          <path
            fill={color}
            d="M25,31.5c0,0.6-0.2,1.2-0.6,1.7c-0.5,0.4-1.1,0.6-1.7,0.6h-7.8c-0.6,0-1.3-0.2-1.7-0.6
	c-0.4-0.4-0.7-1.1-0.6-1.7c0-0.3,0-0.6,0-0.9c0-0.3,0.1-0.6,0.1-1c0.1-0.3,0.1-0.6,0.2-1c0.1-0.3,0.2-0.6,0.4-0.9
	c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.7-0.2,1-0.2c0.1,0,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.3,0.7,0.4
	c0.3,0.2,0.6,0.3,1,0.4c0.8,0.3,1.6,0.3,2.4,0c0.3-0.1,0.7-0.2,1-0.4c0.2-0.2,0.5-0.3,0.7-0.4c0.1-0.1,0.2-0.2,0.4-0.2
	c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.8,0.5c0.2,0.2,0.4,0.5,0.5,0.7c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.2,0.6,0.2,1
	c0.1,0.3,0.1,0.6,0.1,1C25,30.9,25,31.2,25,31.5z M22.2,23.6c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4
	c0.9,0,1.8,0.4,2.4,1C21.8,21.8,22.2,22.7,22.2,23.6L22.2,23.6z"
          />
          <path
            fill={color}
            d="M37.5,31.5c0,0.6-0.2,1.2-0.6,1.7c-0.5,0.4-1.1,0.6-1.7,0.6h-7.8c-0.6,0-1.3-0.2-1.7-0.6
	c-0.4-0.4-0.7-1.1-0.6-1.7c0-0.3,0-0.6,0-0.9c0-0.3,0.1-0.6,0.1-1c0.1-0.3,0.1-0.6,0.2-1c0.1-0.3,0.2-0.6,0.4-0.9
	c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.7-0.2,1-0.2c0.1,0,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.3,0.7,0.4
	c0.3,0.2,0.6,0.3,1,0.4c0.8,0.3,1.6,0.3,2.4,0c0.3-0.1,0.7-0.2,1-0.4c0.2-0.2,0.5-0.3,0.7-0.4c0.1-0.1,0.2-0.2,0.4-0.2
	c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.8,0.5c0.2,0.2,0.4,0.5,0.5,0.7c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.2,0.6,0.2,1
	c0.1,0.3,0.1,0.6,0.1,1C37.5,30.9,37.5,31.2,37.5,31.5z M34.7,23.6c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4
	c0-1.9,1.5-3.4,3.4-3.4c0.9,0,1.8,0.4,2.4,1C34.3,21.8,34.7,22.7,34.7,23.6L34.7,23.6z"
          />
        </svg>
      );
    }
    case ICONS_NAMES.EDIT: {
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1000 1000"
          enableBackground="new 0 0 1000 1000"
          xmlSpace="preserve"
          width={size}
          height={size}
          className={className}
          onClick={onClick}
        >
          <g>
            <g transform="matrix(1 0 0 -1 0 480)">
              <path
                fill={color}
                d="M930.9,420.5c-33.1,31.8-69.3,47.7-108.7,47.7c-44.5,0-83.9-17.2-118.2-51.5L99.4-185.9
			c-3.8-3.8-6.4-8.9-7.6-15.3c-2.5-8.9-29.2-97.9-80.1-267c-3.8-12.7-1.3-23.5,7.6-32.4c6.4-5.1,14-7.6,22.9-7.6h9.5
			c132.2,44.5,219.9,73.1,263.2,85.8c3.8,1.3,7.6,3.8,11.4,7.6c375,370,577.2,570.2,606.4,600.7c38.1,39.4,57.2,79.5,57.2,120.1
			C988.7,346.8,969,384.9,930.9,420.5z M694.4,319.4c31.8-7.6,61.7-25.4,89.6-53.4c26.7-26.7,43.2-57.2,49.6-91.5
			C747.2,88.1,578.7-79.1,328.3-327c-12.7,30.5-29.9,56.6-51.5,78.2c-26.7,26.7-56.6,47-89.6,61L332.1-42.9l210.7,210.7L694.4,319.4
			z M145.2-239.3c31.8-7.6,61-25.4,87.7-53.4c21.6-21.6,36.9-47,45.8-76.3c-12.7-3.8-42.9-14-90.6-30.5s-82.3-28-103.9-34.3
			c6.4,19.1,16.8,52.1,31.5,99.2C130.3-287.6,140.1-255.8,145.2-239.3z M888.9,229.8l-5.7-5.7c-12.7,33.1-31.1,61.7-55.3,85.8
			C802.5,335.3,774.5,355,744,369l1.9,1.9l1.9,1.9c22.9,22.9,47.7,34.3,74.4,34.3c22.9,0,45.1-10.8,66.7-32.4
			c25.4-24.2,38.1-47.7,38.1-70.6S914.4,256.5,888.9,229.8z"
              />
            </g>
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.PHONE: {
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 500 500"
          enableBackground="new 0 0 500 500"
          xmlSpace="preserve"
          width={size}
          height={size}
          className={className}
          onClick={onClick}
        >
          <g id="g3015" transform="matrix(1,0,0,-1,159.45763,1293.0169)">
            <path
              fill={color}
              d="M277.9,943.4c0-5-0.9-11.6-2.8-19.7
		c-1.9-8.1-3.8-14.5-5.9-19.1c-3.9-9.3-15.3-19.2-34-29.6c-17.5-9.5-34.8-14.2-51.9-14.2c-5,0-9.9,0.3-14.6,1s-10.1,1.8-16,3.5
		c-6,1.7-10.4,3-13.3,4c-2.9,1-8,2.9-15.5,5.7c-7.4,2.8-12,4.5-13.7,5c-18.2,6.5-34.5,14.2-48.8,23.2C37.6,917.9,13,938-12.4,963.4
		s-45.4,50-60.1,73.8c-8.9,14.3-16.6,30.6-23.2,48.8c-0.6,1.7-2.2,6.2-5,13.7c-2.8,7.4-4.7,12.6-5.7,15.5s-2.4,7.3-4,13.3
		c-1.7,6-2.8,11.3-3.5,16c-0.7,4.7-1,9.6-1,14.6c0,17.1,4.7,34.4,14.2,51.9c10.4,18.8,20.3,30.1,29.6,34c4.7,2,11,4,19.1,5.9
		s14.6,2.8,19.7,2.8c2.6,0,4.6-0.3,5.9-0.8c3.3-1.1,8.3-8.2,14.8-21.2c2-3.5,4.8-8.6,8.4-15.1c3.5-6.5,6.8-12.4,9.8-17.7
		c3-5.3,5.9-10.3,8.6-14.9c0.6-0.7,2.2-3.1,4.9-7s4.7-7.2,6-9.9c1.3-2.7,2-5.3,2-8c0-3.7-2.7-8.4-8-14s-11.1-10.7-17.3-15.3
		c-6.2-4.7-12-9.6-17.3-14.8c-5.3-5.2-8-9.5-8-12.8c0-1.7,0.5-3.8,1.4-6.3c0.9-2.5,1.7-4.4,2.4-5.7s2-3.5,3.9-6.7s3-4.9,3.2-5.3
		c14.1-25.5,30.3-47.3,48.5-65.6s40.1-34.4,65.6-48.5c0.4-0.2,2.1-1.3,5.3-3.2c3.2-2,5.4-3.3,6.7-3.9c1.3-0.7,3.2-1.4,5.7-2.4
		s4.6-1.4,6.3-1.4c3.3,0,7.6,2.7,12.8,8c5.2,5.3,10.1,11.1,14.8,17.3s9.8,12,15.3,17.3s10.2,8,14,8c2.6,0,5.3-0.7,8-2
		c2.7-1.3,6-3.3,9.9-6c3.9-2.7,6.2-4.3,7-4.9c4.7-2.8,9.6-5.7,14.9-8.6c5.3-3,11.2-6.2,17.7-9.8c6.5-3.5,11.5-6.3,15.1-8.4
		c13-6.5,20.1-11.4,21.2-14.8C277.6,948,277.9,946,277.9,943.4z"
            />
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.EMAIL: {
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 20 14.7"
          enableBackground="new 0 0 20 14.7"
          xmlSpace="preserve"
          width={size}
          height={size}
          className={className}
          onClick={onClick}
        >
          <g>
            <g>
              <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                <path
                  fill={color}
                  className="st0"
                  d="M23.1,5109.8c-9.5-0.5-16.6-5.2-20.8-13.6c-2.5-5.1-2.4-2.2-2.3-60.8l0.1-53.3l1.1-2.9
				c2.5-6.9,8.2-12.6,15.1-15l2.6-0.9h81.2h81.2l2.7,1.1c7.1,2.9,12.3,8.1,14.8,15.2l0.9,2.6l0.1,53.3c0.1,58.6,0.2,55.8-2.3,60.9
				c-3.2,6.5-8.9,11.1-16,12.9c-2.2,0.6-9.9,0.7-78.5,0.7C61.4,5110,25.4,5109.9,23.1,5109.8z M169.8,5091.1
				c0-0.5-68.1-48.6-68.7-48.5c-0.5,0-64.6,48.2-64.6,48.6c0,0.1,30,0.2,66.7,0.2C139.8,5091.4,169.8,5091.3,169.8,5091.1z
				 M59.7,5050c22.4-16.9,40.9-30.8,41.1-30.8s18.4,12.8,40.3,28.4L181,5076l0.1-45.3c0.1-43.3,0-45.4-0.6-46.3
				c-0.4-0.5-1.2-1.3-1.8-1.8l-1.1-0.8H100H22.4l-1.1,0.8c-0.6,0.4-1.5,1.3-1.9,1.9c-0.8,1.1-0.8,1.3-0.9,48.7
				c0,26.2,0,47.6,0.2,47.6C18.9,5080.8,37.3,5067,59.7,5050z"
                />
              </g>
            </g>
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.CAMPAIGNS:
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 35 24.6"
          enableBackground="new 0 0 35 24.6"
          xmlSpace="preserve"
          width={size}
          hanging={size}
          fill={color}
          onClick={onClick}
          className={className}
        >
          <g>
            <path d="M29.1,11.9h2.6c0.5,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8h-2.6c-0.5,0-0.8,0.4-0.8,0.8C28.2,11.5,28.6,11.9,29.1,11.9z" />
            <path d="M29,17.5l-1.3-1.3c-0.4-0.4-0.8-0.4-1.2,0c-0.4,0.4-0.4,0.8,0,1.2l1.3,1.3c0.4,0.4,0.8,0.4,1.2,0S29.4,17.8,29,17.5z" />
            <path d="M26.6,5.8c0.4,0.4,0.8,0.4,1.2,0L29,4.6c0.4-0.4,0.4-0.8,0-1.2s-0.8-0.4-1.2,0l-1.3,1.3C26.2,5,26.2,5.5,26.6,5.8z" />
            <path d="M3.3,7.6C2.9,7.6,2.5,8,2.5,8.5v5.2c0,0.5,0.4,0.8,0.8,0.8h2V7.6L3.3,7.6z" />
            <path d="M13.2,18.5l-6.2-2.2V22c0,0.5,0.4,0.8,0.8,0.8h4.5c0.5,0,0.8-0.4,0.8-0.8V18.5z" />
            <path
              d="M24.2,2.6c0-0.3-0.1-0.5-0.4-0.7S23.3,1.7,23,1.8l-16,5.8v6.8l16,5.8c0.2,0.1,0.5,0.1,0.8-0.1c0.2-0.2,0.4-0.4,0.4-0.7V2.6
		z"
            />
          </g>
        </svg>
      );
    case ICONS_NAMES.ROUND_ARROW: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size + size / 4}
          className={className}
          onClick={onClick}
          viewBox="0 0 100.434 127.293"
        >
          <g transform="matrix(0.875, 0.485, -0.485, 0.875, 57.31, 0)">
            <path
              d="M27.664,116.965a2.542,2.542,0,0,0,4.373,0C39.088,104.116,45.5,91.031,49.258,76.928c.407-2.068-1.865-4.136-3.729-2.915A48.312,48.312,0,0,0,34.749,84.59C45.97,30.111,32.3,6.306,3.146,0c-3.288,0-4.2,3.322-1.763,4.848,31.324,5.458,40.79,33.637,27.942,81.2-3.526-3.119-7.051-6.238-10.374-9.323-1.458-1.458-4.577-.61-4.373,1.865A100.466,100.466,0,0,0,27.664,116.965Zm2.271-24.68c1.254,1.865,4.78,1.458,4.78-1.458v-.407c2.475-2.475,4.78-4.983,7.458-7.458a188.56,188.56,0,0,1-12.238,27.392A92.343,92.343,0,0,1,21.019,85.03c2.271,2.068,4.78,4.136,7.051,6.441A1.558,1.558,0,0,0,29.935,92.285Z"
              transform="translate(0 0)"
              fill={color}
            />
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.SMILE: {
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 512 512"
          enableBackground="new 0 0 512 512"
          xmlSpace="preserve"
          width={size}
          height={size}
          className={className}
          onClick={onClick}
        >
          <circle fill="#FFE17D" cx="256" cy="256" r="256" />
          <path
            fill="#FFD164"
            d="M293.161,474.839c-141.385,0-256-114.615-256-256c0-61.227,21.521-117.411,57.376-161.463
	C36.863,104.316,0,175.842,0,256c0,141.385,114.615,256,256,256c80.159,0,151.685-36.864,198.626-94.538
	C410.573,453.317,354.389,474.839,293.161,474.839z"
          />
          <g>
            <path
              fill="#AA7346"
              d="M379.911,183.742c-3.024,0-6.016-1.315-8.056-3.855c-6.556-8.161-15.435-12.661-24.984-12.661
		c-9.548,0-18.427,4.5-24.984,12.661c-3.573,4.444-10.073,5.153-14.516,1.581s-5.153-10.073-1.581-14.516
		c10.411-12.948,25.379-20.371,41.081-20.371s30.669,7.423,41.081,20.371c3.573,4.444,2.863,10.944-1.581,14.516
		C384.468,183,382.177,183.742,379.911,183.742z"
            />
            <path
              fill="#AA7346"
              d="M198.202,183.742c-3.024,0-6.016-1.315-8.056-3.855c-6.556-8.161-15.435-12.661-24.984-12.661
		s-18.427,4.5-24.984,12.661c-3.573,4.444-10.073,5.153-14.516,1.581s-5.153-10.073-1.581-14.516
		c10.411-12.948,25.379-20.371,41.081-20.371s30.669,7.423,41.081,20.371c3.573,4.444,2.863,10.944-1.581,14.516
		C202.758,183,200.468,183.742,198.202,183.742z"
            />
          </g>
          <path
            fill="#9C6846"
            d="M158.57,264.524c-8.202-2.042-13.562,8.183-7.51,14.083c28.353,27.643,64.914,44.35,104.94,44.35
	s76.587-16.707,104.94-44.35c6.052-5.901,0.692-16.125-7.51-14.083c-30.343,7.554-63.133,11.724-97.43,11.724
	C221.704,276.248,188.913,272.078,158.57,264.524z"
          />
          <path
            fill="#7D5046"
            d="M353.43,264.523c-30.343,7.554-63.134,11.724-97.43,11.724s-67.087-4.17-97.429-11.724
	c-8.202-2.042-13.562,8.182-7.511,14.083c5.451,5.315,11.291,10.085,17.316,14.55c28.185,3.173,57.422,5.026,87.624,5.026
	s59.439-1.852,87.623-5.026c6.025-4.466,11.864-9.236,17.316-14.551C366.992,272.706,361.632,262.481,353.43,264.523z"
          />
          <path
            fill="#FFD164"
            d="M32.948,450.888l66.128,47.234c29.635,21.168,70.23,17.808,95.982-7.944l0,0
	c22.263-22.263,28.127-56.118,14.649-84.572l-25.72-54.297c-2.488-5.252-8.506-7.82-14.019-5.983l0,0
	c-4.693,1.564-7.858,5.956-7.858,10.902v26.753l-55.29-75.396c-4.702-6.412-14.013-7.124-19.635-1.502l0,0
	c-4.051,4.051-4.965,10.28-2.249,15.324l36.299,67.413l-55.076-55.076c-5.057-5.057-13.256-5.057-18.313,0l0,0
	c-4.736,4.736-5.079,12.301-0.791,17.446l50.823,60.988l-61.247-40.831c-5.132-3.421-11.964-2.744-16.325,1.617l0,0
	c-5.38,5.38-4.979,14.217,0.866,19.088l59.188,49.323l-36.391-12.13c-7.952-2.651-16.163,3.268-16.163,11.65l0,0
	C27.806,444.861,29.721,448.583,32.948,450.888z"
          />
          <g>
            <path
              fill="#FFC350"
              d="M123.85,489.864l-90.215-59.34c-3.425,2.135-5.829,5.869-5.829,10.371
		c0,3.966,1.915,7.688,5.142,9.992l66.128,47.234c23.701,16.929,54.392,18.126,78.914,4.773
		C159.474,505.614,140.032,501.422,123.85,489.864z"
            />
            <path
              fill="#FFC350"
              d="M20.027,373.309c-5.046,5.405-4.583,13.97,1.145,18.743l59.398,49.805
		c2.586,2.169,6.203,2.623,9.245,1.161l0,0c5.763-2.769,6.594-10.636,1.537-14.548L20.027,373.309z"
            />
            <path
              fill="#FFC350"
              d="M49.588,332.323c-0.606,0.426-1.2,0.881-1.741,1.422c-4.736,4.735-5.079,12.301-0.791,17.446
		l53.265,63.865c2.41,2.89,6.499,3.73,9.853,2.023l0,0c4.683-2.382,5.876-8.533,2.423-12.493L49.588,332.323z"
            />
            <path
              fill="#FFC350"
              d="M87.185,306.085c-4.051,4.051-4.965,10.28-2.249,15.324l38.519,71.505
		c1.851,3.437,5.842,5.126,9.598,4.064l0,0c5.318-1.504,7.722-7.675,4.824-12.381l-49.129-79.763
		C88.214,305.225,87.67,305.6,87.185,306.085z"
            />
            <path
              fill="#FFC350"
              d="M173.757,344.8c-1.258-0.017-2.535,0.109-3.788,0.527c-4.693,1.564-7.858,5.956-7.858,10.902v26.753
		l6.615,8.448c3.849,4.916,11.724,1.576,10.865-4.608L173.757,344.8z"
            />
          </g>
          <path
            fill="#FFD164"
            d="M479.052,450.888l-66.128,47.234c-29.635,21.168-70.23,17.808-95.982-7.944l0,0
	c-22.263-22.263-28.127-56.118-14.649-84.572l25.72-54.297c2.488-5.252,8.506-7.82,14.019-5.983l0,0
	c4.693,1.564,7.858,5.956,7.858,10.902v26.753l55.29-75.396c4.702-6.412,14.013-7.124,19.635-1.502l0,0
	c4.051,4.051,4.965,10.28,2.249,15.324l-36.299,67.413l55.076-55.076c5.057-5.057,13.256-5.057,18.313,0l0,0
	c4.736,4.736,5.079,12.301,0.791,17.446l-50.823,60.988l61.247-40.831c5.132-3.421,11.964-2.744,16.325,1.617l0,0
	c5.38,5.38,4.979,14.217-0.866,19.088l-59.188,49.323l36.391-12.13c7.952-2.651,16.163,3.268,16.163,11.65l0,0
	C484.194,444.861,482.279,448.583,479.052,450.888z"
          />
          <g>
            <path
              fill="#FFC350"
              d="M388.15,489.864l90.215-59.34c3.425,2.135,5.829,5.869,5.829,10.371
		c0,3.966-1.915,7.688-5.142,9.992l-66.128,47.234c-23.701,16.929-54.392,18.126-78.914,4.773
		C352.526,505.614,371.968,501.422,388.15,489.864z"
            />
            <path
              fill="#FFC350"
              d="M491.973,373.309c5.046,5.405,4.583,13.97-1.145,18.743l-59.398,49.805
		c-2.586,2.169-6.203,2.623-9.245,1.161l0,0c-5.763-2.769-6.594-10.636-1.537-14.548L491.973,373.309z"
            />
            <path
              fill="#FFC350"
              d="M462.412,332.323c0.606,0.426,1.2,0.881,1.741,1.422c4.736,4.735,5.079,12.301,0.791,17.446
		l-53.265,63.865c-2.41,2.89-6.499,3.73-9.853,2.023l0,0c-4.683-2.382-5.876-8.533-2.423-12.493L462.412,332.323z"
            />
            <path
              fill="#FFC350"
              d="M424.815,306.085c4.051,4.051,4.965,10.28,2.249,15.324l-38.519,71.505
		c-1.851,3.437-5.842,5.126-9.598,4.064l0,0c-5.318-1.504-7.722-7.675-4.824-12.381l49.129-79.763
		C423.786,305.225,424.33,305.6,424.815,306.085z"
            />
            <path
              fill="#FFC350"
              d="M338.243,344.8c1.258-0.017,2.535,0.109,3.788,0.527c4.693,1.564,7.858,5.956,7.858,10.902v26.753
		l-6.615,8.448c-3.849,4.916-11.724,1.576-10.865-4.608L338.243,344.8z"
            />
          </g>
        </svg>
      );
    }
    case ICONS_NAMES.SCREEN_RECORD:
      return (
        <svg
          width={size}
          height={size}
          version="1.1"
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 50 44.9"
          enableBackground="new 0 0 50 44.9"
          xmlSpace="preserve"
        >
          <g>
            <path
              d="M43.8,0H6.2C2.8,0,0,2.8,0,6.2v23.9c0,3.4,2.8,6.2,6.2,6.2h17.4v5.9h-8.7c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4
		h20.2c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4h-8.7v-5.9h17.4c3.4,0,6.2-2.8,6.2-6.2V6.2C50,2.8,47.2,0,43.8,0z M6.2,2.8h37.7
		c1.8,0,3.4,1.5,3.4,3.4v20H2.8v-20C2.8,4.3,4.3,2.8,6.2,2.8z M43.8,33.4H6.2c-1.8,0-3.4-1.5-3.4-3.4v-1.1h44.4v1.1
		C47.2,31.9,45.7,33.4,43.8,33.4z"
            />
          </g>
        </svg>
      );
    case ICONS_NAMES.CAMERA_OUTLINE:
      return (
        <svg
          className={className}
          width={size}
          height={size}
          fill={color}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 50 31.6"
          enableBackground="new 0 0 50 31.6"
        >
          <g id="_x37_7_Essential_Icons_72_">
            <path
              id="Video_Camera"
              d="M48,0.3L36.8,7.4V5.3c0-2.9-2.4-5.3-5.3-5.3H5.3C2.4,0,0,2.4,0,5.3v21.1c0,2.9,2.4,5.3,5.3,5.3h26.3
		c2.9,0,5.3-2.4,5.3-5.3v-2.2L48,31.3c1.3,0.7,2-0.5,2-1.1V1.3C50,0.7,49.3-0.5,48,0.3z M34.2,26.3c0,1.4-1.2,2.6-2.6,2.6H5.3
		c-1.4,0-2.6-1.2-2.6-2.6V5.3c0-1.4,1.2-2.6,2.6-2.6h26.3c1.4,0,2.6,1.2,2.6,2.6V26.3z M47.4,27.8l-10.5-6.8V10.5l10.5-6.8V27.8z"
            />
          </g>
        </svg>
      );
    case ICONS_NAMES.TICK:
      return (
        <svg
          className={className}
          fill={color}
          width={size}
          height={size}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 918.2 918.2"
          enableBackground="new 0 0 918.2 918.2"
          xmlSpace="preserve"
        >
          <g>
            <g>
              <path
                d="M664.5,275.9l-11.9-10.2c-19.7-16.9-49.3-15-66.7,4.3L387.3,490.2l-59.9-51.6c-19.7-17-49.3-15.1-66.7,4.2
			l-10.5,11.6c-8.5,9.4-13,21.8-12.3,34.5c0.7,12.6,6.3,24.5,15.8,33.1l109.9,100.6c9.5,8.9,22.2,13.6,35.3,12.9
			c13.1-0.7,25.2-6.6,33.7-16.6l237.1-275.5c8.3-9.6,12.4-22.2,11.4-34.8C680.1,296,674.1,284.1,664.5,275.9z"
              />
            </g>
          </g>
        </svg>
      );
    case ICONS_NAMES.DROPDOWN:
      return (
        <svg
          className={className}
          color={color}
          width={size}
          height={size}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 10.5"
        >
          <path
            id="Polygon_3"
            data-name="Polygon 3"
            d="M6,0l6,10.5H0Z"
            transform="translate(12 10.5) rotate(180)"
          />
        </svg>
      );
    case ICONS_NAMES.RECORD_AGAIN:
      return (
        <svg
          className={className}
          width={size}
          height={size}
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28.846 30.526"
        >
          <g id="Gruppe_208" data-name="Gruppe 208">
            <path
              id="Pfad_542"
              data-name="Pfad 542"
              d="M15.711,30.526a16.018,16.018,0,0,1-6.116-1.2A15.685,15.685,0,0,1,4.6,26.055,15.2,15.2,0,0,1,1.235,21.2a14.909,14.909,0,0,1,0-11.883A15.2,15.2,0,0,1,4.6,4.471,15.7,15.7,0,0,1,9.6,1.2a16.187,16.187,0,0,1,12.233,0,15.663,15.663,0,0,1,4.991,3.272L24.914,6.321a13.285,13.285,0,0,0-18.407,0,12.392,12.392,0,0,0,0,17.884,13.285,13.285,0,0,0,18.407,0l1.906,1.849a15.651,15.651,0,0,1-4.991,3.272A16.009,16.009,0,0,1,15.711,30.526Z"
              transform="translate(0 0)"
              fill="#fff"
            />
            <g
              id="Gruppe_207"
              data-name="Gruppe 207"
              transform="translate(22.528 1.838)"
            >
              <path
                id="Pfad_543"
                data-name="Pfad 543"
                d="M33.6,5.58,35.978,3.2l.405,2.782.476,2.853L34.043,8.4l-2.816-.442Z"
                transform="translate(-30.884 -2.86)"
                fill="#fff"
              />
              <path
                id="Pfad_544"
                data-name="Pfad 544"
                d="M36.7,9.02a.349.349,0,0,1-.053,0l-5.632-.885a.343.343,0,0,1-.189-.582L33.2,5.177,35.575,2.8a.343.343,0,0,1,.582.193l.4,2.779.475,2.85a.343.343,0,0,1-.339.4ZM31.786,7.559l4.5.706-.4-2.385V5.874l-.306-2.105-1.89,1.893h0Zm1.657-2.139h0Z"
                transform="translate(-30.724 -2.699)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Rechteck_166"
            data-name="Rechteck 166"
            transform="translate(11 10.763)"
            fill="#fff"
            stroke="#fff"
            strokeWidth="2"
          >
            <rect width="3" height="10" stroke="none" />
            <rect x="1" y="1" width="1" height="8" fill="none" />
          </g>
          <g
            id="Rechteck_167"
            data-name="Rechteck 167"
            transform="translate(16 10.763)"
            fill="#fff"
            stroke="#fff"
            strokeWidth="2"
          >
            <rect width="3" height="10" stroke="none" />
            <rect x="1" y="1" width="1" height="8" fill="none" />
          </g>
        </svg>
      );
    case ICONS_NAMES.SAVE: {
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width={size}
          height={size}
          onClick={onClick}
          className={className}
          viewBox="0 0 438.533 438.533"
          enableBackground="new 0 0 438.533 438.533"
          xmlSpace="preserve"
        >
          <g>
            <path
              fill={color}
              d="M432.823,121.049c-3.806-9.132-8.377-16.367-13.709-21.695l-79.941-79.942c-5.325-5.325-12.56-9.895-21.696-13.704
		C308.346,1.903,299.969,0,292.357,0H27.409C19.798,0,13.325,2.663,7.995,7.993c-5.33,5.327-7.992,11.799-7.992,19.414v383.719
		c0,7.617,2.662,14.089,7.992,19.417c5.33,5.325,11.803,7.991,19.414,7.991h383.718c7.618,0,14.089-2.666,19.417-7.991
		c5.325-5.328,7.987-11.8,7.987-19.417V146.178C438.531,138.562,436.629,130.188,432.823,121.049z M182.725,45.677
		c0-2.474,0.905-4.611,2.714-6.423c1.807-1.804,3.949-2.708,6.423-2.708h54.819c2.468,0,4.609,0.902,6.417,2.708
		c1.813,1.812,2.717,3.949,2.717,6.423v91.362c0,2.478-0.91,4.618-2.717,6.427c-1.808,1.803-3.949,2.708-6.417,2.708h-54.819
		c-2.474,0-4.617-0.902-6.423-2.708c-1.809-1.812-2.714-3.949-2.714-6.427V45.677z M328.906,401.991H109.633V292.355h219.273
		V401.991z M402,401.991h-36.552h-0.007V283.218c0-7.617-2.663-14.085-7.991-19.417c-5.328-5.328-11.8-7.994-19.41-7.994H100.498
		c-7.614,0-14.087,2.666-19.417,7.994c-5.327,5.328-7.992,11.8-7.992,19.417v118.773H36.544V36.542h36.544v118.771
		c0,7.615,2.662,14.084,7.992,19.414c5.33,5.327,11.803,7.993,19.417,7.993h164.456c7.61,0,14.089-2.666,19.41-7.993
		c5.325-5.327,7.994-11.799,7.994-19.414V36.542c2.854,0,6.563,0.95,11.136,2.853c4.572,1.902,7.806,3.805,9.709,5.708l80.232,80.23
		c1.902,1.903,3.806,5.19,5.708,9.851c1.909,4.665,2.857,8.33,2.857,10.994V401.991z"
            />
          </g>
        </svg>
      );
    }

    default:
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <p
          className={`defaultIcon ${className}`}
          onClick={onClick}
          style={{ width: size, height: size, fontSize: size, color }}
        >
          ✗
        </p>
      );
  }
};

Icon.defaultProps = {
  size: 24,
  color: "#1f86ff",
  className: null,
  onClick: null,
  name: "",
};
Icon.propTypes = {
  name: PropTypes.oneOf([
    "",
    null,
    undefined,
    ICONS_NAMES.MICROPHONE,
    ICONS_NAMES.VOICE,
    ICONS_NAMES.CROSS_CIRCLE,
    ICONS_NAMES.ARROW_RIGHT,
    ICONS_NAMES.RECORD,
    ICONS_NAMES.LANDING_PAGES,
    ICONS_NAMES.CAMERA,
    ICONS_NAMES.VIDEO_LIBRARY,
    ICONS_NAMES.LANDING_LIBRARY,
    ICONS_NAMES.STATICS,
    ICONS_NAMES.INTEGRATE,
    ICONS_NAMES.PLAY,
    ICONS_NAMES.LOGOUT,
    ICONS_NAMES.MORE,
    ICONS_NAMES.PROFILE,
    ICONS_NAMES.SUPPORT,
    ICONS_NAMES.SETTINGS,
    ICONS_NAMES.BILLING,
    ICONS_NAMES.TEAM_MEMBERS,
    ICONS_NAMES.EDIT,
    ICONS_NAMES.PHONE,
    ICONS_NAMES.EMAIL,
    ICONS_NAMES.CAMPAIGNS,
    ICONS_NAMES.SCREEN_RECORD,
    ICONS_NAMES.CAMERA_OUTLINE,
    ICONS_NAMES.TICK,
    ICONS_NAMES.DROPDOWN,
    ICONS_NAMES.RECORD_AGAIN,
    ICONS_NAMES.ROUND_ARROW,
    ICONS_NAMES.SMILE,
    ICONS_NAMES.SAVE,
  ]),
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Icon;
