import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import CustomSelect from "../customSelect";

const TextArea = ({
  name,
  className,
  onChange,
  value,
  rows,
  placeholder,
  maxLength,
  variables,
  defaultValue,
  withInsertTimestamp,
  insertButtonName,
  error,
  id,
  insertValue,
}) => {
  const [textAreaValue, setTextAreaValue] = useState(value);
  const ref = useRef();
  const { current } = ref;
  let cursorPosition = current?.selectionStart;

  const rememberCursorPosition = () =>
    current?.setSelectionRange(cursorPosition, cursorPosition);

  const rememberCursorOnClick = ({
    target: { selectionStart, selectionEnd },
  }) => {
    if (selectionStart === selectionEnd) {
      cursorPosition = selectionStart;
      current?.setSelectionRange(selectionStart, selectionEnd);
    }
  };

  const onChangeValue = (event) => {
    const {
      target: { value: eventValue, selectionStart },
    } = event;

    if (value.length <= maxLength) {
      if (onChange ?? typeof onChange === "function") {
        onChange(event);
        cursorPosition = selectionStart;
        current?.setSelectionRange(cursorPosition, cursorPosition);
        setTextAreaValue(eventValue);
      }
    }
  };

  const onInsertValue = (event) => {
    if (event && current) {
      const {
        target: { value: eventValue },
      } = event;
      const { value: textareaValue, selectionStart: textareaStart } = current;
      cursorPosition = textareaStart;
      const textBeforeCursor = textareaValue.substring(0, cursorPosition);
      const textAfterCursor = textareaValue.substring(
        cursorPosition,
        textareaValue.length
      );
      const textValue = textBeforeCursor + eventValue + textAfterCursor;
      const result = { target: { name, value: textValue } };

      if (textValue.length < maxLength) {
        onChange(result);
        setTextAreaValue(textValue);
      }
    }
  };

  const onInsertButtonClick = () =>
    onInsertValue({
      target: { value: insertValue },
    });

  useEffect(() => setTextAreaValue(value), [value]);

  return (
    <label className={`TextArea ${className}`}>
      <span className="counter">
        {textAreaValue.length}/{maxLength}
      </span>
      {error && <span className="text-red">{error}</span>}
      <textarea
        maxLength={maxLength}
        id={id}
        onClick={rememberCursorOnClick}
        ref={ref}
        onBlur={rememberCursorPosition}
        name={name}
        rows={rows}
        onChange={onChangeValue}
        value={textAreaValue}
        placeholder={placeholder}
      />
      <div className="select-wrapper">
        {variables && (
          <CustomSelect
            resetToDefault
            defaultValue={defaultValue}
            onChange={onInsertValue}
            values={variables}
          />
        )}
        {withInsertTimestamp && (
          <button
            className="insert-button"
            onClick={onInsertButtonClick}
            type="button"
          >
            {insertButtonName}
          </button>
        )}
      </div>
    </label>
  );
};

TextArea.defaultProps = {
  name: "",
  className: "",
  onChange: () => {},
  value: "",
  rows: 4,
  maxLength: 100,
  placeholder: "Enter the text",
  defaultValue: "",
  withInsertTimestamp: false,
  insertButtonName: "insert",
  error: "",
  id: "",
  insertValue: "",
};

TextArea.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  insertButtonName: PropTypes.string,
  variables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  withInsertTimestamp: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string,
  insertValue: PropTypes.string,
};

export default TextArea;
