import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MainRoutes from "../../routes/mainRoutes";
import { ROUTES } from "../../constants/routes";
import { userOperation } from "../../store/user";
import Logo from "../../assets/logo.svg";
import { uiOperation } from "../../store/ui";
import { Icon, Loader, NavDropdown } from "../../components";
import ModalContainer from "../modal";
import "./style.scss";

const Main = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isMobMenuVisible, setMobMenuVisible] = useState(false);
  const menus = useSelector(({ ui }) => ui.menus.main);
  const currentUser = useSelector(({ user: { user } }) => user);
  const [isOpen, setOpen] = useState(false);

  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  const logoutHandler = () => {
    dispatch(userOperation.signOut());
  };

  useEffect(() => {
    dispatch(uiOperation.getMenuRequest());
  }, [uiOperation.getMenuRequest]);

  useEffect(() => {
    if (!currentUser || !currentUser) {
      dispatch(userOperation.getCurrentUserRequest());
    }
  }, [currentUser, userOperation.getCurrentUserRequest]);

  if (!currentUser) return <Loader />;

  return (
    <div className="Main">
      <header>
        <button
          className={`mobMenuBtn ${isMobMenuVisible && "opened"}`}
          aria-label="toggle menu"
          type="button"
          onClick={() => setMobMenuVisible(!isMobMenuVisible)}
        />
        <p className="welcome">Beta Testing Version</p>
        <img className="logo" src={Logo} alt="Logo" />
        <NavDropdown logout={openModal} />
      </header>
      <nav className={`Main_nav ${isMobMenuVisible && "showMobMenu"}`}>
        <img className="logo" src={Logo} alt="Logo" />
        <ul>
          {menus &&
            menus.length &&
            menus.map((menu) => (
              <li key={`routeKey-${menu.title.trim()}`}>
                {menu.highlight ? (
                  <span className="disabled">
                    <Icon
                      className="icon"
                      name={menu.block_type}
                      color="#707070"
                    />
                    <span>{t("{{title}}", { title: menu.title })}</span>
                    <span className="comingSoon">{t("Coming soon")}</span>
                  </span>
                ) : (
                  <NavLink to={`${menu.url}`} activeClassName="active">
                    <Icon className="icon" name={menu.block_type} />
                    <span>{t("{{title}}", { title: menu.title })}</span>
                  </NavLink>
                )}
              </li>
            ))}
        </ul>
      </nav>
      <main>
        <MainRoutes />
      </main>
      <footer>
        <p>{t("© 2019-2020 by parruu AG")}</p>
        <ul>
          <li>
            <Link to={ROUTES.T_C}>{t("T & C")}</Link>
          </li>
          <li>
            <Link to={ROUTES.SUPPORT}>{t("Support")}</Link>
          </li>
          <li>
            <Link to={ROUTES.CONTACT}>{t("Contact & Imprint")}</Link>
          </li>
        </ul>
      </footer>
      <ModalContainer isOpen={isOpen} closeModal={closeModal}>
        <h3 className="modal_confirmation__body__title">
          {t("Are you sure you want to log out?")}
        </h3>
        <div className="modal_confirmation__body__buttons_wrapper">
          <button className="btn btn_confirm " onClick={logoutHandler}>
            {t("Yes")}
          </button>
          <button className="btn btn_confirm " onClick={closeModal}>
            {t("No")}
          </button>
        </div>
      </ModalContainer>
    </div>
  );
};

Main.propTypes = {};

export default Main;
