import React, { useState } from "react";
import useMediaRecorder from "@wmik/use-media-recorder";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCampaignAndLeads } from "../../utils/helpers/campaignHooks";
import { createCampaignVideoRequest } from "../../store/campaigns/operation";
import { useCountdown } from "../../store/ui/hooks";
import VideoControls from "../videoControls";
import SelectRecordOption from "../selectRecordOption";
import FileUpload from "../fileUpload";
import VideoPlayer from "../videoPlayer";
import RecordingPreview from "../recordingPreview";
import WelcomeMessageForm from "../WelcomeMessageForm";
import { campaignsOperation } from "../../store/campaigns";
import {
  CAMPAIGN_STEPS_VALUE,
  RECORDER_STATUS,
  CAMPAIGN_STEPS,
  ICONS_NAMES,
} from "../../constants/common";
import Icon from "../icon";
import "./style.scss";

const VideoGenericTypeScreen = () => {
  const countdown = useCountdown();
  const dispatch = useDispatch();
  const history = useHistory();
  const { campaign } = useCampaignAndLeads();

  const [recordScreen, setRecordScreen] = useState(false);
  const [showForm, setShowForm] = useState(!!campaign.welcome_text);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [videoWasRecorded, setVideoWasRecorded] = useState(false);

  const onStopMediaRecorder = (blob) => {
    const videoFile = new File([blob], `campaign-${campaign.id}.mp4`, {
      type: blob.type,
    });
    setCurrentVideo(videoFile);
  };

  const {
    status,
    liveStream,
    stopRecording,
    startRecording,
  } = useMediaRecorder({
    recordScreen,
    blobOptions: {
      type: "video/mp4",
    },
    mediaStreamConstraints: { video: true, type: "video/mp4", audio: true },
    mediaRecorderOptions: { video: true, type: "video/mp4", audio: true },
    onStop: onStopMediaRecorder,
  });

  const startRecordingHandler = () => {
    if (recordScreen) startRecording();
    else {
      countdown.start().then(() => {
        startRecording();
      });
    }
  };

  const stopRecordingHandler = () => {
    setVideoWasRecorded(true);
    stopRecording();
  };

  const clearCurrentVideo = () => setCurrentVideo(null);

  const changeRecordOption = (isRecordScreen) =>
    setRecordScreen(isRecordScreen);

  const onNextLeadHandler = () => {
    setVideoWasRecorded(false);
    if (currentVideo) {
      dispatch(createCampaignVideoRequest(currentVideo, campaign));
    }
    setCurrentVideo(null);
  };

  const goToLandingsHandler = () => {
    if (campaign?.step !== CAMPAIGN_STEPS_VALUE.LANDING_PAGES) {
      dispatch(
        campaignsOperation.updateCampaignRequest({
          id: campaign.id,
          step: CAMPAIGN_STEPS_VALUE.LANDING_PAGES,
        })
      );
    }
    history.push(
      `/campaigns/${campaign.id}/${
        CAMPAIGN_STEPS[CAMPAIGN_STEPS_VALUE.LANDING_PAGES]
      }`
    );
  };

  const currentLeadVideoSrc =
    campaign.video &&
    campaign.video.video_file_id &&
    `${process.env.REACT_APP_API_URL}/uploads/videos/${campaign.video.video_file_id.filename}`;

  return (
    <div className="video">
      <div className="video__container">
        <div className="video__container__controls">
          <h1 className="title">
            Record or upload a generic video for all leads
          </h1>
          <VideoControls
            videoWasRecorded={videoWasRecorded}
            status={status}
            onStartRecording={startRecordingHandler}
            onStopRecording={stopRecordingHandler}
            recordScreen={recordScreen}
            currentVideo={currentVideo}
          />
          <SelectRecordOption
            disabled={status === RECORDER_STATUS.RECORDING}
            onChange={changeRecordOption}
          />
          <FileUpload
            disabled={status === RECORDER_STATUS.RECORDING}
            title="or"
            withPreview={false}
            withDeleteButton={false}
            accept={["video/mp4"]}
            name="video-upload"
            type="video"
            onChange={(video) => setCurrentVideo(video.target.files[0])}
            btnText="Click to upload video"
            infoText="only MP4 format, max. 200MB"
          />
        </div>
        <div className="video__container__player">
          <h1 className="title m-b-25">
            <Icon
              name={ICONS_NAMES.VIDEO_LIBRARY}
              className="library-icon"
              size={30}
            />
            Videos for new campaign
          </h1>
          {status !== RECORDER_STATUS.RECORDING && (
            <VideoPlayer
              showWelcomeMessage
              onDeleteHandler={clearCurrentVideo}
              mediaBlob={currentVideo}
              src={
                !currentVideo && currentLeadVideoSrc ? currentLeadVideoSrc : ""
              }
              controls={false}
              welcomeMessage={
                campaign.welcome_text
                  ? campaign.welcome_text
                  : "Optional welcome message here"
              }
            />
          )}
          <RecordingPreview stream={liveStream} />
          <div className="buttons-wrapper">
            {currentVideo && (
              <button
                onClick={onNextLeadHandler}
                className="btn btn-blue m-t-10"
              >
                {currentVideo && campaign.video?.video_file_id
                  ? "Update campaign"
                  : "Add video"}
              </button>
            )}
            {currentLeadVideoSrc && (
              <>
                {!showForm && (
                  <button
                    onClick={() => setShowForm(true)}
                    className="btn m-t-10"
                  >
                    Add a welcome message
                  </button>
                )}
                {showForm && <WelcomeMessageForm campaign={campaign} />}
                <button
                  onClick={goToLandingsHandler}
                  className="btn btn-blue m-t-10"
                >
                  Next to landing pages
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoGenericTypeScreen;
