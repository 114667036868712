import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import Icon from "../icon";
import { useOutsideRef } from "../../utils/helpers/common";
import { ICONS_NAMES } from "../../constants/common";

const SelectRecordOption = ({ onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const optionRef = useRef();

  const onClose = () => setIsOpen(false);
  useOutsideRef(optionRef, onClose);

  const [selectedOption, setSelectedOption] = useState({
    value: "Record with camera",
    img: ICONS_NAMES.CAMERA_OUTLINE,
  });

  const handleToggle = () => setIsOpen(!isOpen);

  const onOptionClicked = (option) => () => {
    if (option.recordType === "camera") {
      onChange(false);
    } else onChange(true);
    setSelectedOption(option);
    setIsOpen(false);
  };

  const selected = isOpen && !disabled;

  return (
    <div className="record-option-container" ref={optionRef}>
      <div
        role="presentation"
        className="drop-down-header"
        onClick={handleToggle}
      >
        <span className="header-value">{selectedOption.value}</span>
        <Icon className="icon" name={selectedOption.img} color="inherit" />
        <Icon
          className={`${selected && "selected"}`}
          name={ICONS_NAMES.DROPDOWN}
          color="inherit"
          size={15}
        />
      </div>
      {selected && (
        <div className="drop-down-list-container">
          <ul className="drop-down-list">
            <li
              role="presentation"
              key={`startVariable `}
              className="list-item"
              onClick={onOptionClicked({
                recordType: "camera",
                value: "Record with camera",
                img: ICONS_NAMES.CAMERA_OUTLINE,
              })}
            >
              <span>Record with camera</span>
              <Icon
                className="icon"
                name={ICONS_NAMES.CAMERA_OUTLINE}
                color="inherit"
              />
              {selectedOption.value === "Record with camera" && (
                <Icon size={35} name={ICONS_NAMES.TICK} color="inherit" />
              )}
            </li>
            <li
              role="presentation"
              key="startVariable"
              className="list-item"
              onClick={onOptionClicked({
                recordType: "screen",
                value: "Record screen",
                img: ICONS_NAMES.SCREEN_RECORD,
              })}
            >
              <span>Record screen</span>
              <Icon name={ICONS_NAMES.SCREEN_RECORD} color="inherit" />
              {selectedOption.value === "Record screen" && (
                <Icon size={35} name={ICONS_NAMES.TICK} color="inherit" />
              )}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

SelectRecordOption.defaultProps = {
  disabled: false,
};

SelectRecordOption.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SelectRecordOption;
