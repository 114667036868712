import axios from "axios";
import { path } from "ramda";
import types from "./types";
import apiUrls from "../../constants/apiUrls";
import { uiAction, uiTypes } from "../ui";
import { filesTypes } from "../files";
import { getResErrMessage } from "../../utils/helpers/responseHelpers";
import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_STEPS_VALUE,
  UPLOAD_FILES_TYPES,
} from "../../constants/common";

const getCampaignsRequest = () => ({
  type: types.GET_CAMPAIGNS,
  payload: {
    request: {
      method: "GET",
      url: apiUrls.campaign,
    },
  },
});

const getCampaignRequest = (id) => ({
  type: types.GET_CAMPAIGN,
  payload: {
    request: {
      method: "GET",
      url: `${apiUrls.campaign}/${id}`,
    },
  },
});

const deleteCampaignRequest = (id) => ({
  type: types.DELETE_CAMPAIGN,
  payload: {
    request: {
      method: "DELETE",
      url: `${apiUrls.campaign}/${id}`,
    },
  },
});

const deleteCampaignRequestSuccess = ({ getState, response }) => {
  const { campaigns } = getState();
  const updatedCampaignId = path(["data", "model", "id"], response);

  return campaigns.data.filter((item) => item.id !== updatedCampaignId);
};

const updateCampaignRequest = (campaign) => ({
  type: types.UPDATE_CAMPAIGN,
  payload: {
    request: {
      method: "PUT",
      url: `${apiUrls.campaign}/${campaign.id}`,
      data: campaign,
    },
  },
});

const updateCampaignRequestSuccess = ({ getState, response, action }) => {
  const { campaigns } = getState();
  return {
    campaigns: campaigns.data.map((item) => {
      if (item.id === path(["data", "model", "id"], response)) {
        return {
          ...response.data.model,
          index: path(["payload", "request", "data", "index"], action),
        };
      }
      return item;
    }),
    current: {
      ...response.data.model,
      index: path(["payload", "request", "data", "index"], action),
    },
  };
};

const createCampaignVideoRequest = (video, campaign) => async (
  dispatch,
  getState
) => {
  const {
    campaigns,
    user: { token },
  } = getState();

  const data = new FormData();
  data.append("file", video);
  data.append("attr", UPLOAD_FILES_TYPES.VIDEO);

  dispatch(uiAction.requestLoading(true));
  try {
    const {
      data: { id: videoId },
    } = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}${apiUrls.files}`,
      headers: { Authorization: `Bearer ${token}` },
      data,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: filesTypes.SET_UPLOAD_PROGRESS,
          payload: percentCompleted,
        });
      },
    });

    const {
      data: { model },
    } = await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}${apiUrls.campaign}/${campaign.id}`,
      headers: { Authorization: `Bearer ${token}` },
      data: { temp_video_id: videoId, step: CAMPAIGN_STEPS_VALUE.VIDEOS },
    });

    const newCampaigns = campaigns.data.map((item) => {
      if (item.id === model.id) {
        return { ...model, index: campaign.index };
      }
      return item;
    });

    dispatch({
      type: types.UPDATE_CAMPAIGN_SUCCESS,
      payload: {
        campaigns: newCampaigns,
        current: { ...model, index: campaign.index },
      },
    });
    dispatch(uiAction.requestLoading(false));
  } catch (e) {
    dispatch({ type: uiTypes.SET_ERROR, payload: getResErrMessage(e) });
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: types.UPDATE_CAMPAIGN_FAIL,
      payload: "Request error",
    });
  }
};

const getLeadsRequest = (id) => ({
  type: types.GET_CAMPAIGN_LEADS,
  payload: {
    request: { url: `${apiUrls.campaign}/${id}/leads`, method: "GET" },
  },
});

const createLeadRequest = (id) => ({
  type: types.CREATE_LEAD,
  payload: {
    request: {
      url: `${apiUrls.campaign}/${id}/leads`,
      method: "POST",
    },
  },
});

const createCampaignRequest = (data) => ({
  type: types.CREATE_CAMPAIGN,
  payload: {
    request: {
      url: apiUrls.campaign,
      method: "POST",
      data,
    },
  },
});

const deleteLeadRequest = (id, leadId) => ({
  type: types.DELETE_LEAD,
  payload: {
    request: {
      url: `${apiUrls.campaign}/${id}/leads/${leadId}`,
      method: "DELETE",
    },
  },
});

const updateLeadRequest = (id, leadId, data) => ({
  type: types.UPDATE_LEAD,
  payload: {
    request: {
      url: `${apiUrls.campaign}/${id}/leads/${leadId}`,
      method: "PUT",
      data,
    },
  },
});

const uploadVideoAndUpdateLead = (video, id, leadId) => async (
  dispatch,
  getState
) => {
  const {
    campaigns: { leads },
    user: { token },
  } = getState();
  const data = new FormData();
  data.append("file", video);
  data.append("attr", UPLOAD_FILES_TYPES.VIDEO);
  dispatch(uiAction.requestLoading(true));
  try {
    const {
      data: { id: videoId },
    } = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}${apiUrls.files}`,
      headers: { Authorization: `Bearer ${token}` },
      data,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: filesTypes.SET_UPLOAD_PROGRESS,
          payload: percentCompleted,
        });
      },
    });

    const {
      data: { model },
    } = await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}${apiUrls.campaign}/${id}/leads/${leadId}`,
      headers: { Authorization: `Bearer ${token}` },
      data: { temp_video_id: videoId },
    });

    const newLeadsList = leads.map((lead) => {
      if (lead.id === model.id) {
        return { ...model };
      }
      return lead;
    });

    dispatch({
      type: types.UPDATE_LEAD_SUCCESS,
      payload: newLeadsList,
    });
    dispatch(uiAction.requestLoading(false));
  } catch (e) {
    dispatch({ type: uiTypes.SET_ERROR, payload: getResErrMessage(e) });
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: types.UPDATE_LEAD_FAIL,
      payload: "Request error",
    });
  }
};

const addNewLeadsList = (csv, campaign) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  const data = new FormData();
  data.append("file", csv);
  data.append("attr", UPLOAD_FILES_TYPES.CSV);
  dispatch(uiAction.requestLoading(true));
  try {
    const {
      data: { id: csvId },
    } = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}${apiUrls.files}`,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });
    await dispatch(
      updateCampaignRequest({
        ...campaign,
        csv_file_id: csvId,
        step: CAMPAIGN_STEPS_VALUE.LEADS,
        status: CAMPAIGN_STATUSES.draft,
      })
    );
    await dispatch(getLeadsRequest(campaign.id));
    dispatch(uiAction.requestLoading(false));
  } catch (e) {
    dispatch({ type: uiTypes.SET_ERROR, payload: getResErrMessage(e) });
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: types.UPDATE_CAMPAIGN_FAIL,
      payload: "Request error",
    });
  }
};

const addNewLead = (campaignId) => async (dispatch) => {
  await dispatch(createLeadRequest(campaignId));
  await dispatch(getLeadsRequest(campaignId));
};

const updateLeadFields = (id, leadId, data) => async (dispatch, getState) => {
  const {
    campaigns: { leads },
    user: { token },
  } = getState();
  dispatch(uiAction.requestLoading(true));
  try {
    const {
      data: { model },
    } = await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}${apiUrls.campaign}/${id}/leads/${leadId}`,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const newLeadsList = leads.map((lead) => {
      if (lead.id === model.id) {
        return { ...model };
      }
      return lead;
    });
    dispatch({
      type: types.UPDATE_LEAD_SUCCESS,
      payload: newLeadsList,
    });
    dispatch(uiAction.requestLoading(false));
  } catch (e) {
    dispatch(uiAction.requestLoading(false));
    dispatch({
      type: types.UPDATE_LEAD_FAIL,
      payload: "Request error",
    });
  }
};

const publishCampaignRequest = (data) => ({
  type: types.PUBLISH_CAMPAIGN,
  payload: {
    request: {
      method: "POST",
      url: apiUrls.publishCampaign,
      data,
    },
  },
});

export {
  getCampaignsRequest,
  getCampaignRequest,
  getLeadsRequest,
  createCampaignRequest,
  deleteLeadRequest,
  updateLeadRequest,
  deleteCampaignRequest,
  deleteCampaignRequestSuccess,
  updateCampaignRequest,
  updateCampaignRequestSuccess,
  uploadVideoAndUpdateLead,
  updateLeadFields,
  createCampaignVideoRequest,
  addNewLeadsList,
  createLeadRequest,
  addNewLead,
  publishCampaignRequest,
};
