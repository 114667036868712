import ForgotPassword from "./forgotPassword";
import ResetPassword from "./resetPassword";
import SignIn from "./signIn";
import SignUp from "./signUp";

export default {
  ForgotPassword,
  ResetPassword,
  SignIn,
  SignUp,
};
