import { path } from "ramda";

const getResErrMessage = (error) => {
  const errors = path(["response", "data", "errors"], error);
  const oneErr = path(["response", "data", "error", "message"], error);
  const errCSV = path(["response", "data", "message"], error);
  const errVideo = path(["response", "message"], error);

  let message = "Unknown error";

  if (errors) {
    message = "";
    Object.keys(errors).forEach((key) => {
      message += `${key.replace("_", " ")}: ${errors[key].message} </br>`;
    });
  } else if (oneErr && typeof oneErr === "string") {
    message = oneErr;
  } else if (errCSV && typeof errCSV === "string") {
    message = errCSV;
  } else if (errVideo && typeof errVideo === "string") {
    message = errVideo;
  }
  return message;
};

export { getResErrMessage };
