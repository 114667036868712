import React from "react";
import { useTranslation } from "react-i18next";
import CSV from "../../assets/img/CSV.png";
import Example from "../../assets/img/Example.png";
import "./style.scss";

const InfoCSV = () => {
  const { t } = useTranslation();
  return (
    <div className="info_csv">
      <ul className="info_csv__points">
        <li>
          {t(
            "The variables of the column header need to match the variables of the landing pages, if you wish to personalize them."
          )}
        </li>
        <li>
          {t(
            "Start with a simple spreadsheet and save as .CSV, or export from your CRM."
          )}
        </li>
      </ul>
      <h5 className="info_csv__title">{t("CSV file format example")}</h5>
      <div className="info_csv__img_wrapper">
        <img src={Example} alt={t("Example of CSV")} />
        <img src={CSV} alt={t("CSV file")} />
      </div>
    </div>
  );
};

export default InfoCSV;
