import PropTypes from "prop-types";
import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_VIDEO_TYPES,
} from "../../constants/common";

export const CampaignModel = {
  button_title: PropTypes.string,
  button_url: PropTypes.string,
  content: PropTypes.string,
  csv_file_id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      id: PropTypes.number,
      filename: PropTypes.string,
      original: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
      created_by: PropTypes.number,
      is_secured: PropTypes.number,
      model_name: PropTypes.string,
      model_id: PropTypes.number,
      group: PropTypes.number,
    }),
  ]),
  hash: PropTypes.string,
  id: PropTypes.number,
  logo_file_id: PropTypes.oneOfType([
    PropTypes.shape({
      filename: PropTypes.string,
      group: PropTypes.number,
      id: PropTypes.number,
      model_id: PropTypes.number,
      original: PropTypes.string,
    }),
    PropTypes.number,
  ]),
  preview_code: PropTypes.string,
  seo_url: PropTypes.string,
  status: PropTypes.oneOf([
    CAMPAIGN_STATUSES.inactive,
    CAMPAIGN_STATUSES.active,
    CAMPAIGN_STATUSES.draft,
  ]),
  step: PropTypes.number,
  title: PropTypes.string,
  user_id: PropTypes.number,
  video_type: PropTypes.oneOf([
    CAMPAIGN_VIDEO_TYPES.none,
    CAMPAIGN_VIDEO_TYPES.each,
    CAMPAIGN_VIDEO_TYPES.generic,
  ]),
};

export const CampaignsModel = PropTypes.arrayOf(PropTypes.shape(CampaignModel));

export const LeadModel = PropTypes.shape({
  id: PropTypes.number,
  campaign_id: PropTypes.number,
  video_id: PropTypes.number,
  fields: PropTypes.shape(),
});
