import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { campaignsOperation } from "../../store/campaigns";
import { CAMPAIGN_STEPS, CAMPAIGN_STEPS_VALUE } from "../../constants/common";

const ChangeNextLeadVideoBtn = ({
  onNext,
  currentVideo,
  withVideo,
  active,
  leads,
  campaign,
}) => {
  if (leads && leads.length === withVideo.length && !currentVideo) {
    const history = useHistory();
    const dispatch = useDispatch();

    const goToLandingsHandler = () => {
      if (campaign?.step !== CAMPAIGN_STEPS_VALUE.LANDING_PAGES) {
        dispatch(
          campaignsOperation.updateCampaignRequest({
            id: campaign.id,
            step: CAMPAIGN_STEPS_VALUE.LANDING_PAGES,
          })
        );
      }
      history.push(
        `/campaigns/${campaign.id}/${
          CAMPAIGN_STEPS[CAMPAIGN_STEPS_VALUE.LANDING_PAGES]
        }`
      );
    };

    return (
      <button onClick={goToLandingsHandler} className="btn btn-blue">
        Next to landing pages
      </button>
    );
  }

  if (currentVideo && active.video_id) {
    return (
      <button onClick={onNext} className="btn btn-blue">
        Update Lead
      </button>
    );
  }

  if (active && active.video_id && !currentVideo) {
    return (
      <button
        disabled={!currentVideo && !!withVideo.length && !active.video_id}
        onClick={onNext}
        className="btn btn-blue"
      >
        Next lead
      </button>
    );
  }

  if (currentVideo && !active.video_id) {
    return (
      <button onClick={onNext} className="btn btn-blue">
        Next lead
      </button>
    );
  }

  return null;
};

ChangeNextLeadVideoBtn.defaultProps = {
  active: null,
  leads: null,
  currentVideo: null,
};
ChangeNextLeadVideoBtn.propTypes = {
  onNext: PropTypes.func.isRequired,
  currentVideo: PropTypes.shape(),
  withVideo: PropTypes.arrayOf(PropTypes.object).isRequired,
  active: PropTypes.shape(),
  leads: PropTypes.arrayOf(PropTypes.object),
  campaign: PropTypes.shape().isRequired,
};

export default ChangeNextLeadVideoBtn;
