import { handleActions } from "redux-actions";
import types from "./types";
import { mergeIn } from "../../utils/helpers/stateHelpers";

const initialState = {
  token: null,
  user: null,
};

const reducer = handleActions(
  {
    [types.LOGOUT]: () => initialState,
    [types.LOGIN_REQUEST_SUCCESS]: mergeIn(({ payload: { data } }) => ({
      token: data.token,
      user: data.user,
    })),
    [types.REGISTER_REQUEST_SUCCESS]: mergeIn(({ payload: { data } }) => ({
      token: data.token,
      user: {
        ...data.user,
        is_first_login: 1,
      },
    })),
    [types.USER_UPDATE_FIRST_AUTH_SUCCESS]: mergeIn(
      ({ payload: { data } }) => ({
        user: data.user,
      })
    ),
    [types.GET_CURRENT_USER_SUCCESS]: mergeIn(
      ({
        payload: {
          data: { user },
        },
      }) => ({ user })
    ),
    [types.GET_CURRENT_USER_FAIL]: () => ({ user: null, token: null }),
  },
  initialState
);

export default reducer;
