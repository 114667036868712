import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const AuthFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="AuthFooter">
      <div className="AuthFooter_left">
        <a href="/">{t("T & C")}</a>
        <a href="/">{t("Contact & Imprint")}</a>
      </div>
      <div className="AuthFooter_right">
        <p>{t("© 2019-2020 by parruu AG")}</p>
      </div>
    </div>
  );
};

export default AuthFooter;
