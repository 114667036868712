import { handleActions } from "redux-actions";
import types from "./types";
import { mergeIn } from "../../utils/helpers/stateHelpers";

const initialState = {
  video: null,
  csv: null,
  image: null,
  audio: null,
  previewPage: "",
  loadingProgress: 0,
  createPageData: "",
  videoPageTemplate: null,
  landingPageUrl: null,
  createVideoScript: null,
  videoTemplate: null,
  trainingRecord: null,
  videoPagePreviewURL: null,
  campaign: null,
};

const reducer = handleActions(
  {
    [types.SEND_VIDEO_SUCCESS]: mergeIn(({ payload: { data } }) => ({
      video: data,
    })),
    [types.SEND_CSV_SUCCESS]: mergeIn(({ payload: { data } }) => ({
      csv: data,
    })),
    [types.SET_CREATE_PAGE_DATA]: mergeIn(({ payload }) => ({
      createPageData: payload,
    })),
    [types.SET_VIDEO_SCRIPT]: mergeIn(({ payload }) => ({
      createVideoScript: payload,
    })),
    [types.SEND_LOGO_SUCCESS]: mergeIn(({ payload: { data } }) => ({
      image: data,
    })),
    [types.SEND_AUDIO_SUCCESS]: mergeIn(({ payload: { data } }) => ({
      audio: data,
    })),
    [types.DELETE_FILE]: mergeIn(({ payload: key }) => ({ [key]: null })),
    [types.CREATE_VIDEO_PAGE_SUCCESS]: mergeIn(
      ({
        payload: {
          data: { model },
        },
      }) => ({
        videoPageTemplate: { ...model },
      })
    ),
    [types.CREATE_VIDEO_SUCCESS]: mergeIn(
      ({
        payload: {
          data: { model },
        },
      }) => ({
        videoTemplate: { ...model },
      })
    ),
    [types.GET_LANDING_PAGE_URL_SUCCESS]: mergeIn(({ payload: { data } }) => ({
      landingPageUrl: data,
    })),
    [types.GET_VIDEO_PAGE_PREVIEW_SUCCESS]: mergeIn(
      ({
        payload: {
          request: { responseURL },
        },
      }) => ({
        videoPagePreviewURL: responseURL,
      })
    ),

    [types.SET_UPLOAD_PROGRESS]: mergeIn(({ payload }) => ({
      loadingProgress: payload,
    })),
    [types.SET_TRAINING_RECORD]: mergeIn(({ payload }) => ({
      trainingRecord: payload,
    })),
  },
  initialState
);

export default reducer;
