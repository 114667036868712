import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { ICONS_NAMES } from "../../constants/common";
import Icon from "../icon";
import "./style.scss";

const LeadsVideoList = ({ leads, className }) => {
  return (
    <div className={`lead-video-list-container ${className}`}>
      <div className="lead-video-list">
        <ul>
          {leads?.map((lead, index) => (
            <li className="lead-video-item" key={`LeadsVideoList${lead.id}`}>
              <span>
                Video {index + 1} for {Object.values(lead.fields)[0]}
              </span>
              <div>
                <NavLink
                  to={`/campaigns/${lead.campaign_id}/videos/${lead.id}`}
                  className="edit-button"
                >
                  <Icon
                    className="edit-icon"
                    name={ICONS_NAMES.EDIT}
                    size={25}
                    alt="edit"
                    color="#393f4d"
                  />
                  Edit
                </NavLink>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

LeadsVideoList.defaultProps = {
  className: "",
};
LeadsVideoList.propTypes = {
  leads: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  className: PropTypes.string,
};

export default LeadsVideoList;
