import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const api = process.env.REACT_APP_API_URL;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    debug: false, // For debugging
    saveMissing: false, // send missing keys to addPath
    backend: {
      loadPath: `${api}/locales/{{lng}}/landing.json`, // Path for getting languages
      // addPath: `${api}/locales/{{lng}}/{{ns}}`, // Path for sending missing keys
    },
    react: {
      useSuspense: false,
    },
  });

const changeLanguage = (lng = "en") => {
  i18n.changeLanguage(lng);
};

export default i18n;

export { changeLanguage };
