import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_STEPS_VALUE,
} from "../../constants/common";
import CampaignListItem from "./campaignListItem";
import { CampaignsModel } from "../../utils/helpers/defPropTypes";
import { campaignsOperation } from "../../store/campaigns";
import "./style.scss";

const CampaignsList = ({ campaigns }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formatCampaigns, setFormatCampaigns] = useState({
    draft: [],
    active: [],
    inactive: [],
  });

  const formatCampaignsHandler = () => {
    const formCampaignsObj = {
      draft: [],
      active: [],
      inactive: [],
    };
    if (campaigns && campaigns.length) {
      campaigns.forEach((campaign, i) => {
        switch (campaign.status) {
          case CAMPAIGN_STATUSES.active:
            formCampaignsObj.active.push({ ...campaign, index: i });
            break;
          case CAMPAIGN_STATUSES.inactive:
            formCampaignsObj.inactive.push({ ...campaign, index: i });
            break;
          default:
            formCampaignsObj.draft.push({ ...campaign, index: i });
            break;
        }
      });
    }

    setFormatCampaigns(formCampaignsObj);
  };

  const deleteHandler = (campaign) => {
    dispatch(campaignsOperation.deleteCampaignRequest(campaign.id));
  };

  const changeStatusHandler = (campaign, checked) => {
    dispatch(
      campaignsOperation.updateCampaignRequest({
        id: campaign.id,
        step: CAMPAIGN_STEPS_VALUE.PUBLISH_COMPLETE,
        index: campaign.index,
        csv_file_id: campaign.csv_file_id,
        status: checked ? CAMPAIGN_STATUSES.active : CAMPAIGN_STATUSES.inactive,
      })
    );
  };

  useEffect(() => {
    formatCampaignsHandler();
  }, [campaigns]);

  if (!campaigns || !campaigns.length) return null;

  return (
    <div className="CampaignsList">
      {formatCampaigns.draft && formatCampaigns.draft.length > 0 && (
        <figure className="CampaignsList_category">
          <figcaption className="CampaignsList_category__title">
            {t("Draft campaigns")}
          </figcaption>
          <ul className="CampaignsList_category__list m-b-25">
            {formatCampaigns.draft.map((campaign) => (
              <CampaignListItem
                key={`campaignItem-${campaign.id}`}
                onDelete={deleteHandler}
                campaign={campaign}
              />
            ))}
          </ul>
        </figure>
      )}

      {formatCampaigns.active && formatCampaigns.active.length > 0 && (
        <figure className="CampaignsList_category">
          <figcaption className="CampaignsList_category__title">
            {t("Active campaigns")}
          </figcaption>
          <ul className="CampaignsList_category__list m-b-25">
            {formatCampaigns.active.map((campaign) => (
              <CampaignListItem
                key={`campaignItem-${campaign.id}`}
                onDelete={deleteHandler}
                onChangeStatus={changeStatusHandler}
                campaign={campaign}
              />
            ))}
          </ul>
        </figure>
      )}

      {formatCampaigns.inactive && formatCampaigns.inactive.length > 0 && (
        <figure className="CampaignsList_category">
          <figcaption className="CampaignsList_category__title">
            {t("Inactive campaigns")}
          </figcaption>
          <ul className="CampaignsList_category__list m-b-25">
            {formatCampaigns.inactive.map((campaign) => (
              <CampaignListItem
                key={`campaignItem-${campaign.id}`}
                onDelete={deleteHandler}
                onChangeStatus={changeStatusHandler}
                campaign={campaign}
              />
            ))}
          </ul>
        </figure>
      )}
    </div>
  );
};

CampaignsList.propTypes = {
  campaigns: CampaignsModel.isRequired,
};

export default CampaignsList;
