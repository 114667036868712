import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { equals } from "ramda";
import { deleteFile } from "../../store/files/operation";
import {
  addNewLead,
  addNewLeadsList,
  deleteLeadRequest,
  updateLeadFields,
  updateCampaignRequest,
} from "../../store/campaigns/operation";
import { CAMPAIGN_STEPS_VALUE, ICONS_NAMES } from "../../constants/common";
import ConfirmModal from "../confirmModal";
import Icon from "../icon";
import "./style.scss";
import Loader from "../loader";
import { CampaignModel } from "../../utils/helpers/defPropTypes";

const LeadsAdded = ({ leads, campaign }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef = useRef();
  const loading = useSelector(({ ui: { requestLoading } }) => requestLoading);
  const { id: campaignId, fields: campaignFields } = campaign;

  const [leadIsEdited, setLeadIsEdited] = useState({});
  const [editedLeadFields, setEditedLeadFields] = useState(null);
  const [modalData, setModalData] = useState({
    status: false,
    data: {
      onSubmit: null,
      modalText: "",
    },
  });

  const closeModal = () => {
    setModalData({
      status: false,
      data: {
        onSubmit: null,
        modalText: "",
      },
    });
  };

  const editLineHandler = (id, fields) => {
    setEditedLeadFields({ ...fields });
    setLeadIsEdited({ [id]: true });
  };

  const saveLineHandler = (id, fields) => {
    if (!equals(editedLeadFields, fields)) {
      dispatch(updateLeadFields(campaignId, id, { fields: editedLeadFields }));
    }
    setEditedLeadFields(null);
    setLeadIsEdited({});
  };

  const addNewLineHandler = () => {
    dispatch(addNewLead(campaign.id));
  };

  const deleteLineHandler = (data) => {
    dispatch(deleteLeadRequest(campaignId, data));
    setEditedLeadFields(null);
    setLeadIsEdited({});
    closeModal();
  };

  const getFileHandler = ({ target: { files } }) => {
    dispatch(deleteFile("csv"));
    if (files) {
      const [file] = files;
      dispatch(addNewLeadsList(file, campaign));
    }
  };

  const addNewLeadsConfirmation = () => {
    inputRef.current.click();
    closeModal();
  };

  const deleteLead = (id) => {
    setModalData({
      status: true,
      data: {
        onSubmit: () => deleteLineHandler(id),
        modalText: "Are you sure you want to delete this list?",
      },
    });
  };

  const deleteLeadsList = () => {
    setModalData({
      status: true,
      data: {
        onSubmit: addNewLeadsConfirmation,
        modalText:
          "Are you sure you want to delete your list? If you delete your list all " +
          "your video and landing pages are lost and you need to start your " +
          "campaign again",
      },
    });
  };

  const nextToVideosHandler = useCallback(() => {
    if (campaign?.step !== CAMPAIGN_STEPS_VALUE.VIDEOS) {
      dispatch(
        updateCampaignRequest({
          id: campaign.id,
          step: CAMPAIGN_STEPS_VALUE.VIDEOS,
        })
      );
    }
    history.push(`/campaigns/${campaign.id}/videos`);
  }, [campaign.id]);

  const editLineValueHandler = (event, key) => {
    editedLeadFields[key] = event.currentTarget.value;
  };

  if (loading) return <Loader />;

  return (
    <div className="leads-container">
      <h1 className="add-leads-title">The following leads have been added</h1>
      <table className="leads-container__tab">
        <thead>
          <tr className="leads-container__tab__header">
            {campaignFields.map(({ key, title }) => (
              <th key={key}>{title}</th>
            ))}
            <th className="control-col">Edit</th>
            <th className="control-col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {leads.map(({ id, fields }) => (
            <tr key={`leadRow${id}`} className="leads-container__tab__row">
              {campaignFields.map(({ key }) =>
                leadIsEdited[id] ? (
                  <td key={`leadItem${key}`}>
                    <input
                      type="text"
                      onChange={(event) => editLineValueHandler(event, key)}
                      defaultValue={fields[key]}
                    />
                  </td>
                ) : (
                  <td key={`leadItem${key}`}>{fields[key]}</td>
                )
              )}
              <td>
                {leadIsEdited[id] ? (
                  <button
                    className="save-button"
                    onClick={() => saveLineHandler(id, fields)}
                  >
                    Save
                  </button>
                ) : (
                  <div className="leads-container__tab__row__action">
                    <button
                      disabled={Object.values(leadIsEdited)[0]}
                      onClick={() => editLineHandler(id, fields)}
                    >
                      <Icon name={ICONS_NAMES.EDIT} size={20} color="#393f4d" />
                      <span>Edit</span>
                    </button>
                  </div>
                )}
              </td>
              <td className="leads-container__tab__row__action">
                <button onClick={() => deleteLead(id)}>
                  <Icon
                    size={30}
                    name={ICONS_NAMES.CROSS_CIRCLE}
                    color="#393f4d"
                  />
                  <span>Delete</span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="add-line-button" onClick={addNewLineHandler}>
        <Icon
          size={35}
          name={ICONS_NAMES.CROSS_CIRCLE}
          className="add-line-icon"
          color="#393f4d"
        />
        <span>Add line</span>
      </button>
      <div className="leads-container__btn-wrapper">
        <button className="btn btn-blue" onClick={nextToVideosHandler}>
          Next to create videos
        </button>
        <label
          role="presentation"
          htmlFor={inputRef}
          onClick={deleteLeadsList}
          className="add-new-csv delete-list btn-outline-blue btn"
        >
          <Icon
            size={30}
            name={ICONS_NAMES.CROSS_CIRCLE}
            className="delete-list-icon"
            color="#1f86ff"
          />
          Delete list and add new
        </label>
        <input
          className="add-new-leads-input"
          onChange={getFileHandler}
          ref={inputRef}
          type="file"
          id="file"
          accept=".csv"
        />
      </div>
      <ConfirmModal
        isOpen={modalData}
        closeModal={closeModal}
        onSubmit={modalData?.data.onSubmit}
        btnText={{ yes: "Yes", no: "Not now" }}
        modalText={modalData?.data.modalText}
      />
    </div>
  );
};

LeadsAdded.propTypes = {
  leads: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  campaign: PropTypes.shape(CampaignModel).isRequired,
};

export default LeadsAdded;
