import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { checkIsFunction } from "../../utils/helpers/common";

const InputNew = ({ type, name, value, onChange, placeholder, maxLength }) => {
  const [inputValue, setInputValue] = useState(value);

  const onChangeHandler = (e) => {
    if (e.target.value.length <= maxLength) {
      if (checkIsFunction(onChange)) {
        onChange(e);
      }
      setInputValue(e.target.value);
    }
  };

  useEffect(() => setInputValue(value), [value]);

  return (
    <div className="Input">
      <span className="Input_counter">
        {inputValue?.length || 0}/{maxLength}
      </span>
      <input
        autoComplete="off"
        type={type}
        name={name}
        value={inputValue}
        onChange={onChangeHandler}
        placeholder={placeholder}
      />
    </div>
  );
};

InputNew.defaultProps = {
  type: "text",
  name: "",
  value: "",
  placeholder: "",
  onChange: () => {},
  maxLength: 100,
};
InputNew.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InputNew;
