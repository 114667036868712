import React from "react";
import PropTypes from "prop-types";

const Terms = ({ history: { goBack } }) => (
  <div className="text-center p-t-100 p-l-30 p-r-30">
    <h1 className="m-b-30">Terms & Conditions</h1>
    <div className="m-b-30">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Autem culpa ducimus eos esse, expedita, fugiat illo inventore iure minus
        non numquam odit officiis quasi quos repellat repellendus reprehenderit
        sunt voluptate. Lorem ipsum dolor sit amet, consectetur adipisicing
        elit. Autem culpa ducimus eos esse, expedita, fugiat illo inventore iure
        minus non numquam odit officiis quasi quos repellat repellendus
        reprehenderit sunt voluptate. Lorem ipsum dolor sit amet, consectetur
        adipisicing elit. Autem culpa ducimus eos esse, expedita, fugiat illo
        inventore iure minus non numquam odit officiis quasi quos repellat
        repellendus reprehenderit sunt voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Autem culpa ducimus eos esse, expedita, fugiat illo inventore iure minus
        non numquam odit officiis quasi quos repellat repellendus reprehenderit
        sunt voluptate. Lorem ipsum dolor sit amet, consectetur adipisicing
        elit. Autem culpa ducimus eos esse, expedita, fugiat illo inventore iure
        minus non numquam odit officiis quasi quos repellat repellendus
        reprehenderit sunt voluptate. Lorem ipsum dolor sit amet, consectetur
        adipisicing elit. Autem culpa ducimus eos esse, expedita, fugiat illo
        inventore iure minus non numquam odit officiis quasi quos repellat
        repellendus reprehenderit sunt voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Autem culpa ducimus eos esse, expedita, fugiat illo inventore iure minus
        non numquam odit officiis quasi quos repellat repellendus reprehenderit
        sunt voluptate. Lorem ipsum dolor sit amet, consectetur adipisicing
        elit. Autem culpa ducimus eos esse, expedita, fugiat illo inventore iure
        minus non numquam odit officiis quasi quos repellat repellendus
        reprehenderit sunt voluptate. Lorem ipsum dolor sit amet, consectetur
        adipisicing elit. Autem culpa ducimus eos esse, expedita, fugiat illo
        inventore iure minus non numquam odit officiis quasi quos repellat
        repellendus reprehenderit sunt voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa
        ducimus eos esse, expedita, fugiat illo inventore iure minus non numquam
        odit officiis quasi quos repellat repellendus reprehenderit sunt
        voluptate. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Autem culpa ducimus eos esse, expedita, fugiat illo inventore iure minus
        non numquam odit officiis quasi quos repellat repellendus reprehenderit
        sunt voluptate. Lorem ipsum dolor sit amet, consectetur adipisicing
        elit. Autem culpa ducimus eos esse, expedita, fugiat illo inventore iure
        minus non numquam odit officiis quasi quos repellat repellendus
        reprehenderit sunt voluptate. Lorem ipsum dolor sit amet, consectetur
        adipisicing elit. Autem culpa ducimus eos esse, expedita, fugiat illo
        inventore iure minus non numquam odit officiis quasi quos repellat
        repellendus reprehenderit sunt voluptate.
      </p>
    </div>
    <button className="btn btn-white" onClick={goBack}>
      Go back
    </button>
  </div>
);

Terms.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

export default Terms;
