import React from "react";
import PropTypes from "prop-types";
import { ICONS_NAMES } from "../../constants/common";
import Icon from "../icon";
import "./style.scss";

const LandingPageItem = ({ page, onEdit }) => {
  const editHandler = () => {
    if (onEdit && typeof onEdit === "function") {
      onEdit(page);
    }
  };
  return (
    <li className="LandingPageItem">
      <p>{page.title}</p>
      <button className="LandingPageItem_edit" onClick={editHandler}>
        <Icon
          name={ICONS_NAMES.EDIT}
          color="inherit"
          size={20}
          className="m-r-5"
        />
        <span>Edit</span>
      </button>
    </li>
  );
};

LandingPageItem.defaultProps = { onEdit: null };
LandingPageItem.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  onEdit: PropTypes.func,
};

export default LandingPageItem;
