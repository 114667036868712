import React from "react";
import PropTypes from "prop-types";
import { NavLink, useParams } from "react-router-dom";
import { CampaignModel } from "../../utils/helpers/defPropTypes";
import { CAMPAIGN_STEPS_VALUE } from "../../constants/common";
import "./style.scss";

const CampaignTabsItem = ({ active, campaignId, label, to }) => {
  const isDisabled = (e) => {
    if (!active) {
      e.preventDefault();
    }
  };

  return (
    <NavLink
      onClick={isDisabled}
      className={active ? "" : "disabled"}
      to={`/campaigns${Number(campaignId) ? `/${campaignId}` : ""}${
        to && `/${to}`
      }`}
    >
      {label}
    </NavLink>
  );
};

CampaignTabsItem.propTypes = {
  active: PropTypes.bool.isRequired,
  campaignId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const CampaignTabs = ({ campaign }) => {
  const { campaignId } = useParams();
  return (
    <nav className="CampaignTabs">
      <CampaignTabsItem
        active
        campaignId={campaignId}
        label="Leads"
        to="leads"
      />
      <CampaignTabsItem
        active={!!Number(campaignId)}
        campaignId={campaignId}
        label="videos"
        to="videos"
      />
      <CampaignTabsItem
        active={campaign?.step >= CAMPAIGN_STEPS_VALUE.LANDING_PAGES}
        campaignId={campaignId}
        label="landing pages"
        to="landing-pages"
      />
      <CampaignTabsItem
        active={campaign?.step >= CAMPAIGN_STEPS_VALUE.PUBLISH}
        campaignId={campaignId}
        label="publish"
        to="publish"
      />
    </nav>
  );
};

CampaignTabs.defaultProps = {
  campaign: null,
};
CampaignTabs.propTypes = {
  campaign: PropTypes.shape(CampaignModel),
};

export default CampaignTabs;
