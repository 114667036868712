import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import "./style.scss";

const Input = ({
  name,
  type,
  error,
  touched,
  className,
  checkBoxLabel,
  ...props
}) => (
  <div className={`form-control m-b-20 ${className}`}>
    <div
      className={`form-control-input input-${type} ${
        error && touched && "form-control-input-error"
      }`}
    >
      {type === "checkbox" ? (
        <label htmlFor={`${name}-${type}`}>
          <Field id={`${name}-${type}`} name={name} type={type} {...props} />
          {checkBoxLabel && <span>{checkBoxLabel}</span>}
        </label>
      ) : (
        <Field autoComplete="off" name={name} type={type} {...props} />
      )}
    </div>
    <ErrorMessage className="form-error" name={name} component="div" />
  </div>
);

Input.defaultProps = {
  error: null,
  className: "",
  checkBoxLabel: null,
  touched: false,
};
Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  checkBoxLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
};

export default Input;
