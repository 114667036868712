import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useOutsideRef } from "../../utils/helpers/common";
import { ICONS_NAMES } from "../../constants/common";
import Icon from "../icon";
import "./style.scss";

const CustomSelect = ({
  onChange,
  className,
  values,
  defaultValue,
  resetToDefault,
  name,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const dropdownRef = useRef();

  const onClose = () => setIsOpen(false);
  useOutsideRef(dropdownRef, onClose);

  const handleToggle = () => setIsOpen(!isOpen);

  const onOptionClicked = (option) => () => {
    onChange({
      target: { value: `{{${option.title}}}`, name },
    });
    if (resetToDefault) setSelectedOption(defaultValue);
    else setSelectedOption(option.title);
    setIsOpen(false);
  };

  return (
    <div className="drop-down-container" ref={dropdownRef}>
      <div
        role="presentation"
        className="drop-down-header"
        onClick={handleToggle}
      >
        <div>{selectedOption || defaultValue}</div>
        <Icon
          size={15}
          name={ICONS_NAMES.DROPDOWN}
          className={`${isOpen && "selected"}`}
        />
      </div>
      {isOpen && (
        <div className="drop-down-list-container">
          {values.length ? (
            <ul className={`drop-down-list ${className}`}>
              {values.map((option) => (
                <li
                  role="presentation"
                  key={`startVariable ${option.key}`}
                  className="list-item"
                  onClick={onOptionClicked(option)}
                >
                  {option.title}
                </li>
              ))}
            </ul>
          ) : (
            <ul className="drop-down-list">
              <li className="list-item">No CSV added </li>
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

CustomSelect.defaultProps = {
  onChange: () => {},
  className: "",
  resetToDefault: false,
  name: "",
};
CustomSelect.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultValue: PropTypes.string.isRequired,
  resetToDefault: PropTypes.bool,
  name: PropTypes.string,
};

export default CustomSelect;
