import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import "./styles.scss";

const AuthForm = ({ title, children, onSubmit, initialValues, validate }) => {
  const { t } = useTranslation();
  return (
    <div className="AuthForm">
      {title && (
        <h4 className="AuthForm_title m-b-35 p-l-20 p-r-20">{t(title)}</h4>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={onSubmit}
      >
        {children}
      </Formik>
    </div>
  );
};

AuthForm.defaultProps = {
  title: null,
  validate: null,
};
AuthForm.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.any,
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  validate: PropTypes.shape(),
};

export default AuthForm;
