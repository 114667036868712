export default {
  SET_ERROR: "SET_ERROR",

  GET_MENU: "GET_MENU",
  GET_MENU_SUCCESS: "GET_MENU_SUCCESS",
  GET_MENU_FAIL: "GET_MENU_FAIL",

  REQUEST_LOADING: "REQUEST_LOADING",

  COUNTDOWN_CHANGE: "COUNTDOWN_CHANGE",
};
