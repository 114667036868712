import React from "react";
import { Form } from "formik";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AuthFooter, AuthHeader, Input } from "../../../components";
import { AuthForm } from "../../../containers";
import { ROUTES } from "../../../constants/routes";
import { signUpValidator } from "./signUpValidator";
import { registerRequest } from "../../../store/user/operation";
import "../style.scss";

const SignUp = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialValues = {
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    password: "",
    agree: false,
  };

  const registerHandler = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await dispatch(registerRequest(values));
    setSubmitting(false);
  };

  return (
    <div className="Auth">
      <AuthHeader buttonData={{ text: t("Sign In"), link: ROUTES.LOGIN }} />

      <div className="Auth_content signUp">
        <AuthForm
          title={t("Convert more leads. Personalize your videos with AI.")}
          initialValues={initialValues}
          validate={signUpValidator}
          onSubmit={registerHandler}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className="Auth_form">
              <div className="Auth_form__row m-b-25">
                <Input
                  className="m-r-15"
                  name="first_name"
                  type="text"
                  error={t(errors.first_name)}
                  touched={touched.first_name}
                  placeholder={t("First name")}
                />
                <Input
                  name="last_name"
                  type="text"
                  error={t(errors.last_name)}
                  touched={touched.last_name}
                  placeholder={t("Last name")}
                />
              </div>
              <Input
                className="m-b-25"
                name="company_name"
                type="text"
                error={t(errors.company_name)}
                touched={touched.company_name}
                placeholder={t("Company name")}
              />
              <Input
                className="m-b-25"
                name="email"
                type="email"
                error={t(errors.email)}
                touched={touched.email}
                placeholder={t("E-mail address")}
              />
              <Input
                className="m-b-25"
                name="password"
                type="password"
                error={t(errors.password)}
                touched={touched.password}
                placeholder={t("Password")}
              />
              <Input
                className="m-b-25"
                name="agree"
                type="checkbox"
                error={t(errors.agree)}
                touched={touched.agree}
                checkBoxLabel={
                  <>
                    {t("I agree with the")}{" "}
                    <Link to={`${ROUTES.TERMS}`}>
                      {t("terms & privacy policy")}
                    </Link>
                    .
                  </>
                }
              />
              <button
                className="btn btn-block"
                type="submit"
                disabled={isSubmitting}
              >
                {t("Create account")}
              </button>
            </Form>
          )}
        </AuthForm>
      </div>

      <AuthFooter />
    </div>
  );
};

export default SignUp;
