import { userTypes } from "../../store/user";
import { filesTypes } from "../../store/files";
import { campaignsTypes } from "../../store/campaigns";
import { uiTypes, uiAction } from "../../store/ui";
import Storage from "./Storage";
import history from "./history";
import { START_ROUTE_LINK } from "../../constants/common";
import {
  deleteCampaignRequestSuccess,
  updateCampaignRequestSuccess,
} from "../../store/campaigns/operation";
import { getResErrMessage } from "./responseHelpers";

export default {
  onSuccess: ({ action, next, response, dispatch, getState }) => {
    // Disable loading after request
    dispatch(uiAction.requestLoading(false));

    switch (action.type) {
      case campaignsTypes.UPDATE_CAMPAIGN: {
        next({
          type: `${action.type}_SUCCESS`,
          payload: updateCampaignRequestSuccess({ getState, response }),
        });
        break;
      }
      case campaignsTypes.DELETE_CAMPAIGN:
        next({
          type: `${action.type}_SUCCESS`,
          payload: deleteCampaignRequestSuccess({ getState, response }),
        });
        break;
      case userTypes.LOGIN_REQUEST: {
        history.push(START_ROUTE_LINK);
        Storage.set(process.env.REACT_APP_TOKEN_KEY, response.data.token);
        next({ type: `${action.type}_SUCCESS`, payload: response });
        break;
      }
      case userTypes.REGISTER_REQUEST: {
        history.push(START_ROUTE_LINK);
        Storage.set(process.env.REACT_APP_TOKEN_KEY, response.data.token);
        next({ type: `${action.type}_SUCCESS`, payload: response });
        break;
      }
      case filesTypes.GET_VIDEO_PAGE_PREVIEW: {
        const win = window.open(response.request.responseURL, "_blank");
        win.focus();
        next({ type: `${action.type}_SUCCESS`, payload: response });
        break;
      }
      default: {
        next({ type: `${action.type}_SUCCESS`, payload: response });
        break;
      }
    }
  },
  onError: ({ action, next, error, dispatch }) => {
    const message = getResErrMessage(error);

    // Disable loading after request
    dispatch(uiAction.requestLoading(false));

    dispatch({ type: uiTypes.SET_ERROR, payload: message });

    next({
      type: `${action.type}_FAIL`,
      payload: error,
    });
  },
  interceptors: {
    request: [
      {
        success({ dispatch }, req) {
          // Enable loading before request
          dispatch(uiAction.requestLoading(true));
          return req;
        },
      },
    ],
  },
};
