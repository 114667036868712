import React, { useEffect, useRef } from "react";

// eslint-disable-next-line react/prop-types
const RecordingPreview = ({ stream }) => {
  const videoPreviewRef = useRef();

  useEffect(() => {
    if (videoPreviewRef.current && stream) {
      videoPreviewRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }
  return (
    <div className="video_wrapper">
      <div className="video_wrapper__container">
        <video ref={videoPreviewRef} autoPlay className="video" poster="">
          <track kind="captions" />
        </video>
      </div>
    </div>
  );
};

export default RecordingPreview;
