import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiTypes } from "./index";

export const useCountdown = () => {
  const countdown = useSelector(({ ui }) => ui.countdown);
  const dispatch = useDispatch();

  const changeCountDownHandler = () => {
    if (countdown && countdown > 0) {
      setTimeout(() => {
        dispatch({
          type: uiTypes.COUNTDOWN_CHANGE,
          payload: countdown - 1,
        });
      }, 1000);
    }
  };

  const startCountdownHandler = async (seconds = 3) => {
    dispatch({
      type: uiTypes.COUNTDOWN_CHANGE,
      payload: seconds,
    });

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(countdown);
      }, seconds * 1000);
    });
  };

  useEffect(() => {
    changeCountDownHandler();
  }, [countdown]);

  return { start: startCountdownHandler };
};
