const FILES_TYPES = {
  VIDEO: {
    DEFAULT: "video",
    MP4: "video/mp4",
  },
  IMAGE: {
    JPEG: "image/jpeg",
    PNG: "image/png",
  },
  TEXT: "text",
};

const FORM_INITIAL_VALUES = {
  ADD_SCRIPT: `
Add the script of your video by typing here
    
Format example:

„Hi {{Firstname}}[start:00:02][end:00:04], I hope all is well in 
{{Location}}[start:00:04][end:00:06]? I’ve recorded a little video for 
you. Do you guys also work with sales videos at {{Company}}
[start:00:08][end:00:10]? If so, let’s get in touch! Cheers from Zurich“
`,
  CREATE_PAGE: {
    TITLE: `Example:

Increase your conversion with personalization, {{Firstname}}!`,
    BODY: `Example:

You are using sales videos on landing pages at {{Company}} in {{Location}}.

The results are great but the conversion rate can’t be high enough, right?

Personalize your landing page with already existing data on your leads in a few clicks will help skyrocket your CR!

We’ve helped clients like {{Client1}} or {{Client2}} with our no-bullshit page builder for sales reps who are busy selling and not with complicated tools.`,
  },
};

const DEFAULT_SELECT_VARIABLES = [
  { name: "FirstName", value: "{{FirstName}}" },
  { name: "Company", value: "{{Company}}" },
  { name: "Location", value: "{{Location}}" },
  { name: "Custom (must fit CSV)", value: "{{}}" },
];

const START_ROUTE_LINK = "/campaigns";

const UPLOAD_FILES_TYPES = {
  LOGO: "image",
  CSV: "csv",
  VIDEO: "video",
};

const UPLOAD_FILE_STATUS = {
  START: 0,
  DONE: 100,
};

const CAMPAIGN_STATUSES = {
  draft: 1,
  active: 5,
  inactive: 10,
};

const CAMPAIGN_VIDEO_TYPES = {
  none: 0,
  generic: 1,
  each: 2,
};

const CAMPAIGN_STEPS = {
  0: "leads",
  1: "videos",
  2: "landing-pages",
  3: "publish",
  4: "publish", // For CampaignListItem as "PUBLISH_COMPLETE"
};

const CAMPAIGN_STEPS_VALUE = {
  LEADS: 0,
  VIDEOS: 1,
  LANDING_PAGES: 2,
  PUBLISH: 3,
  PUBLISH_COMPLETE: 4,
};

const RECORDER_STATUS = {
  RECORDING: "recording",
};

const ICONS_NAMES = {
  MICROPHONE: "microphone",
  VOICE: "voice",
  CROSS_CIRCLE: "cross-circle",
  ARROW_RIGHT: "arrow-right",
  RECORD: "record",
  LANDING_PAGES: "landing-pages",
  CAMERA: "camera",
  VIDEO_LIBRARY: "video-library",
  LANDING_LIBRARY: "landing-library",
  STATICS: "statics",
  INTEGRATE: "integrate",
  PLAY: "play",
  LOGOUT: "logout",
  MORE: "more",
  PROFILE: "profile",
  SUPPORT: "support",
  SETTINGS: "settings",
  BILLING: "billing",
  TEAM_MEMBERS: "team-members",
  EDIT: "edit",
  PHONE: "phone",
  EMAIL: "email",
  CAMPAIGNS: "campaigns",
  ROUND_ARROW: "round-arrow",
  SMILE: "smile",
  SCREEN_RECORD: "screen-record",
  CAMERA_OUTLINE: "camera-outline",
  TICK: "tick",
  DROPDOWN: "dropdown",
  RECORD_AGAIN: "record-again",
  SAVE: "save",
};

export {
  ICONS_NAMES,
  UPLOAD_FILE_STATUS,
  UPLOAD_FILES_TYPES,
  FILES_TYPES,
  FORM_INITIAL_VALUES,
  DEFAULT_SELECT_VARIABLES,
  START_ROUTE_LINK,
  CAMPAIGN_STATUSES,
  CAMPAIGN_VIDEO_TYPES,
  CAMPAIGN_STEPS,
  RECORDER_STATUS,
  CAMPAIGN_STEPS_VALUE,
};
