import { handleActions } from "redux-actions";
import { mergeIn } from "../../utils/helpers/stateHelpers";
import types from "./types";
import { userTypes } from "../user";

const initialState = {
  data: [],
  current: null,
  leads: null,
};

const reducer = handleActions(
  {
    [userTypes.LOGOUT]: () => initialState,
    [types.GET_CAMPAIGNS_SUCCESS]: mergeIn(
      ({
        payload: {
          data: { data },
        },
      }) => ({ data })
    ),
    [types.GET_CAMPAIGN_SUCCESS]: mergeIn(
      ({
        payload: {
          data: { model },
        },
      }) => ({
        current: model,
      })
    ),
    [types.CLEAR_CURRENT_CAMPAIGN]: mergeIn(() => ({ current: null })),
    [types.CLEAR_CAMPAIGN_LEADS]: mergeIn(() => ({ leads: null })),
    [types.GET_CAMPAIGN_LEADS_SUCCESS]: mergeIn(
      ({
        payload: {
          data: { data },
        },
      }) => ({
        leads: data,
      })
    ),
    [types.CREATE_CAMPAIGN_SUCCESS]: mergeIn(
      ({
        payload: {
          data: { model },
        },
      }) => ({
        current: model,
      })
    ),
    [types.UPDATE_CAMPAIGN_SUCCESS]: mergeIn(
      ({ payload: { campaigns, current } }) => ({
        data: campaigns,
        current,
      })
    ),
    [types.DELETE_CAMPAIGN_SUCCESS]: mergeIn(({ payload }) => ({
      data: payload,
    })),
    [types.UPDATE_LEAD_SUCCESS]: mergeIn(({ payload }) => ({
      leads: payload,
    })),
    [types.DELETE_LEAD_SUCCESS]: mergeIn(
      (
        {
          payload: {
            data: { model },
          },
        },
        { leads }
      ) => ({
        leads: leads.filter((lead) => lead.id !== model.id),
      })
    ),
  },
  initialState
);

export default reducer;
