import React from "react";
import PropTypes from "prop-types";
import { ICONS_NAMES, RECORDER_STATUS } from "../../constants/common";
import Icon from "../icon";
import "./style.scss";

const VideoControls = ({
  status,
  onStopRecording,
  onStartRecording,
  recordScreen,
  currentVideo,
  videoWasRecorded,
}) => {
  return (
    <div className="control-buttons">
      {status !== RECORDER_STATUS.RECORDING ? (
        <button
          className="btn-red btn"
          onClick={onStartRecording}
          disabled={status === RECORDER_STATUS.RECORDING || currentVideo}
        >
          {videoWasRecorded ? (
            <>
              <Icon name={ICONS_NAMES.RECORD_AGAIN} color="inherit" size={25} />
              <span>Record video again</span>
            </>
          ) : (
            <>
              <Icon name={ICONS_NAMES.CAMERA} color="inherit" size={25} />
              <span>Start to record video</span>
            </>
          )}
        </button>
      ) : (
        <button
          className="btn-red btn"
          onClick={onStopRecording}
          disabled={status !== RECORDER_STATUS.RECORDING}
        >
          <span className="stop-icon">stop</span>
          <span>{recordScreen ? "Confirm" : "Stop recording"}</span>
        </button>
      )}
    </div>
  );
};

VideoControls.defaultProps = {
  currentVideo: null,
};
VideoControls.propTypes = {
  status: PropTypes.string.isRequired,
  onStopRecording: PropTypes.func.isRequired,
  onStartRecording: PropTypes.func.isRequired,
  recordScreen: PropTypes.bool.isRequired,
  currentVideo: PropTypes.shape({}),
  videoWasRecorded: PropTypes.bool.isRequired,
};
export default VideoControls;
