// Show credentials in https://reqres.in/

export default {
  login: `/api/auth/login`,
  register: "/api/auth/register",
  currentUser: "/api/auth/current-user",
  defaultCredentials: {
    email: "testemail@gmail.com",
    password: "12341234",
  },
  files: "/api/files",
  videoPageTemplates: "/api/video-page-templates",
  videoTemplate: "/api/video-templates",
  menu: "/api/menus",
  preview: "/preview-campaign",
  campaign: "/api/campaigns",
  updateUserProfile: "/api/profile/user",
  publishCampaign: "/api/campaigns/publish",
};
