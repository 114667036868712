import React from "react";
import { useHistory } from "react-router-dom";
import "./styles.scss";

const Page404 = () => {
  const history = useHistory();
  const goBackHandler = () => history.goBack();
  return (
    <div className="page404">
      <h1>404</h1>
      <h4>Page not found</h4>
      <p className="m-b-30">Please try again later</p>
      <button className="btn" onClick={goBackHandler}>
        Go back
      </button>
    </div>
  );
};

export default Page404;
