import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./style.scss";

const ModalContainer = ({ isOpen = false, children, closeModal }) => {
  const { t } = useTranslation();
  useLayoutEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);
  return (
    isOpen &&
    ReactDOM.createPortal(
      <div className="modal_container">
        <div className="modal_confirmation__body">
          <button onClick={closeModal} className="modal_confirmation_close">
            {t("Close")}
          </button>
          {children}
        </div>
      </div>,
      document.body
    )
  );
};

ModalContainer.defaultProps = {
  isOpen: false,
};

ModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default ModalContainer;
