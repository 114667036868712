import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { deleteFile, uploadFile } from "../../store/files/operation";
import {
  CAMPAIGN_STEPS_VALUE,
  CAMPAIGN_VIDEO_TYPES,
} from "../../constants/common";
import { campaignsOperation } from "../../store/campaigns";
import FileUpload from "../fileUpload";
import InfoCSV from "../InfoCSV";
import Loader from "../loader";
import "./style.scss";

const AddLeads = () => {
  const history = useHistory();
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const csv = useSelector((store) => store.files.csv);
  const loading = useSelector(({ ui: { requestLoading } }) => requestLoading);

  const getFileHandler = ({ target: { files } }) => {
    if (files) {
      const [file] = files;
      dispatch(uploadFile(file, "csv"));
    }
  };

  useEffect(() => {
    if (csv && csv.id) {
      if (Number(campaignId)) {
        dispatch(
          campaignsOperation.updateCampaignRequest({
            id: +campaignId,
            csv_file_id: csv.id,
            step: CAMPAIGN_STEPS_VALUE.LEADS,
            video_type: CAMPAIGN_VIDEO_TYPES.none,
          })
        );
      } else {
        dispatch(
          campaignsOperation.createCampaignRequest({
            csv_file_id: csv.id,
            step: CAMPAIGN_STEPS_VALUE.LEADS,
          })
        );
      }
    }
    return () => dispatch(deleteFile("csv"));
  }, [
    csv,
    campaignsOperation.createCampaignRequest,
    campaignsOperation.updateCampaignRequest,
    deleteFile,
  ]);

  const skipAddLeadsHandler = () => {
    dispatch(
      campaignsOperation.createCampaignRequest({
        step: CAMPAIGN_STEPS_VALUE.VIDEOS,
        video_type: CAMPAIGN_VIDEO_TYPES.generic,
      })
    );
    history.push("videos");
  };

  return (
    <div className="add_variables_step">
      {loading ? (
        <Loader />
      ) : (
        <>
          <h1 className="add-leads-title">
            Start your campaign by adding leads
          </h1>
          <div className="container">
            <FileUpload
              withDeleteButton={false}
              type="text"
              accept=".csv"
              className="upload_csv"
              infoText="only .csv format, max. 10 columns (10 variables), max. 100 lines"
              btnText="Click to upload CSV file"
              onChange={getFileHandler}
            />
            <InfoCSV />
          </div>
          <button
            className="btn btn-outline-blue"
            onClick={skipAddLeadsHandler}
          >
            Skip this step for a single lead
          </button>
        </>
      )}
    </div>
  );
};

export default AddLeads;
