import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { path } from "ramda";
import Icon from "../icon";
import ConfirmModal from "../confirmModal";
import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_STEPS,
  ICONS_NAMES,
} from "../../constants/common";
import { CampaignModel } from "../../utils/helpers/defPropTypes";

const CampaignListItem = ({
  campaign,
  onDelete,
  onChangeStatus,
  className,
}) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState({ status: false, data: null });
  const [modalContent, setModalContent] = useState("Are you shore?");
  const userToken = useSelector(({ user: { token } }) => token);
  const downloadCSVFileUrl = `${
    process.env.REACT_APP_API_URL
  }/api/campaigns/${path(["id"], campaign)}/csv-urls?token=${userToken}`;

  const closeModal = () => setIsOpenModal({ status: false });
  const openModal = (data) => {
    let modalText;
    switch (data.type) {
      case "delete":
        modalText = t(
          "Are you sure you want to delete {{title}}? You will loose all your data. This cannot be undone.",
          { title: campaign.title }
        );
        break;
      case "changeStatus":
        modalText = t(
          "Are you sure you want to {{status}} your campaign {{title}}?",
          {
            status: data.checked ? "activate" : "disactivate",
            title: campaign.title,
          }
        );
        break;
      default:
        modalText = t("Are you shore?");
        break;
    }
    setModalContent(modalText);
    setIsOpenModal({ status: true, data });
  };

  const deleteHandler = () => {
    openModal({ type: "delete" });
  };

  const changeStatusHandler = ({ target: { checked } }) => {
    openModal({ type: "changeStatus", checked });
  };

  const getStatusClassName = () => {
    switch (campaign.status) {
      case CAMPAIGN_STATUSES.active:
        return "Active";
      case CAMPAIGN_STATUSES.inactive:
        return "Inactive";
      default:
        return "Draft";
    }
  };

  const submitModalHandler = ({ data }) => {
    if (data.type === "changeStatus") {
      if (onChangeStatus && typeof onChangeStatus === "function") {
        onChangeStatus(campaign, data.checked);
      }
    } else if (
      data.type === "delete" &&
      onDelete &&
      typeof onDelete === "function"
    ) {
      onDelete(campaign);
    }

    closeModal();
  };

  return (
    <>
      <li className={`CampaignListItem ${className} ${getStatusClassName()}`}>
        <p className="CampaignListItem_title">{campaign.title}</p>
        {campaign.status !== CAMPAIGN_STATUSES.draft && (
          <>
            <a
              className="CampaignListItem_csv"
              target="_blank"
              rel="noreferrer"
              href={downloadCSVFileUrl}
            >
              {t("Download CSV with URLs")}
            </a>
            <label className="CampaignListItem_toggle">
              <input
                type="checkbox"
                onChange={changeStatusHandler}
                checked={campaign.status === CAMPAIGN_STATUSES.active}
              />
              <span className="switch" />
            </label>
          </>
        )}
        <div className="CampaignListItem_action">
          <NavLink
            to={`/campaigns/${campaign.id}/${CAMPAIGN_STEPS[campaign.step]}`}
            className="m-r-25"
          >
            <Icon
              name={ICONS_NAMES.EDIT}
              className="m-r-5"
              size={15}
              color="#393f4d"
            />
            <span>{t("Edit")}</span>
          </NavLink>
          <button onClick={deleteHandler}>
            <Icon name={ICONS_NAMES.CROSS_CIRCLE} color="#393f4d" />
            <span>{t("Delete")}</span>
          </button>
        </div>
      </li>

      <ConfirmModal
        isOpen={isOpenModal}
        closeModal={closeModal}
        onSubmit={submitModalHandler}
        btnText={{ yes: t("Yes"), no: t("Not now") }}
      >
        <h3 className="modal_confirmation__body__title">{modalContent}</h3>
      </ConfirmModal>
    </>
  );
};

CampaignListItem.defaultProps = {
  onDelete: null,
  onChangeStatus: null,
  className: "",
};
CampaignListItem.propTypes = {
  campaign: PropTypes.shape(CampaignModel).isRequired,
  onDelete: PropTypes.func,
  onChangeStatus: PropTypes.func,
  className: PropTypes.string,
};

export default CampaignListItem;
