import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const LoadingProgress = ({ done, className }) => {
  return (
    <div className={`progress-container ${className}`}>
      <p className="text">
        Your videos are being processed. This takes a few minutes.
      </p>
      <div className="progress_wrapper">
        <div className="progress">
          <div className="progress-done" style={{ width: `${done}%` }} />
        </div>
        <p className="percent">{done}%</p>
      </div>
    </div>
  );
};

LoadingProgress.defaultProps = { className: "" };
LoadingProgress.propTypes = {
  done: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default LoadingProgress;
