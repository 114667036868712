import React, { useEffect, useState } from "react";
import useMediaRecorder from "@wmik/use-media-recorder";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { filter, findIndex, propEq } from "ramda";
import {
  useActiveLeadByParams,
  useCampaignAndLeads,
} from "../../utils/helpers/campaignHooks";
import { useCountdown } from "../../store/ui/hooks";
import CampaignVideosLeadSteps from "../campaignVideosLeadSteps";
import ChangeNextLeadVideoBtn from "./changeNextLeadVideoBtn";
import { uploadVideoAndUpdateLead } from "../../store/campaigns/operation";
import VideoControls from "../videoControls";
import SelectRecordOption from "../selectRecordOption";
import FileUpload from "../fileUpload";
import VideoPlayer from "../videoPlayer";
import RecordingPreview from "../recordingPreview";
import LeadsVideoList from "../leadsVideoList";
import { ICONS_NAMES, RECORDER_STATUS } from "../../constants/common";
import Icon from "../icon";
import "./style.scss";

const VideoEachTypeScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const countdown = useCountdown();

  const { leads, campaign } = useCampaignAndLeads();
  const { active, index } = useActiveLeadByParams(leads);
  const [withVideo, setWithVideo] = useState([]);

  const [recordScreen, setRecordScreen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [videoWasRecorded, setVideoWasRecorded] = useState(false);

  const onStopMediaRecorder = (blob) => {
    const videoFile = new File([blob], `lead-${active.id}.mp4`, {
      type: blob.type,
    });
    setCurrentVideo(videoFile);
  };

  const {
    status,
    liveStream,
    stopRecording,
    startRecording,
  } = useMediaRecorder({
    recordScreen,
    blobOptions: { type: "video/mp4" },
    mediaStreamConstraints: { video: true, type: "video/mp4", audio: true },
    mediaRecorderOptions: { video: true, type: "video/mp4", audio: true },
    onStop: onStopMediaRecorder,
  });

  const startRecordingHandler = () => {
    if (recordScreen) startRecording();
    else {
      countdown.start().then(() => {
        startRecording();
      });
    }
  };

  const stopRecordingHandler = () => {
    setVideoWasRecorded(true);
    stopRecording();
  };

  const clearCurrentVideo = () => setCurrentVideo(null);

  const changeRecordOption = (isRecordScreen) =>
    setRecordScreen(isRecordScreen);

  const onNextLeadHandler = () => {
    setVideoWasRecorded(false);
    if (currentVideo) {
      dispatch(uploadVideoAndUpdateLead(currentVideo, campaign.id, active.id));
    }
    if (leads[index]) {
      let first = findIndex(propEq("video_id", null))(leads);
      if (first >= 0) {
        if (!active.video_id) {
          first += 1;
        }
        history.push(`/campaigns/${campaign.id}/videos/${leads[first].id}`);
      }
    }
    setCurrentVideo(null);
  };

  const currentLeadVideoSrc =
    active?.video &&
    `${process.env.REACT_APP_API_URL}/uploads/videos/${active.video.video_file_id.filename}`;

  // Set leads with videos
  useEffect(() => {
    const getLeadsWithVideos = () => {
      if (leads && leads.length) {
        setWithVideo(filter((a) => a.video_id, leads));
      }
    };
    getLeadsWithVideos();
  }, [leads]);

  return (
    <div className="video">
      <div className="video__container">
        <div className="video__container__controls">
          <h1 className="title">
            Record or upload a personalized video for each lead
          </h1>
          <CampaignVideosLeadSteps
            leads={leads}
            active={active}
            index={index}
            className="m-b-30 m-t-25"
          />
          <VideoControls
            videoWasRecorded={videoWasRecorded}
            status={status}
            onStartRecording={startRecordingHandler}
            onStopRecording={stopRecordingHandler}
            recordScreen={recordScreen}
            currentVideo={currentVideo}
          />
          <SelectRecordOption
            disabled={status === RECORDER_STATUS.RECORDING}
            onChange={changeRecordOption}
          />
          <FileUpload
            disabled={status === RECORDER_STATUS.RECORDING}
            title="or"
            withPreview={false}
            withDeleteButton={false}
            accept={["video/mp4"]}
            name="video-upload"
            type="video"
            onChange={(video) => setCurrentVideo(video.target.files[0])}
            btnText="Click to upload video"
            infoText="only MP4 format, max. 200MB"
          />
        </div>
        <div className="video__container__player">
          <h1 className="title m-b-25">
            <Icon
              name={ICONS_NAMES.VIDEO_LIBRARY}
              className="library-icon"
              size={30}
            />
            Videos for new campaign
          </h1>
          {status !== RECORDER_STATUS.RECORDING && (
            <VideoPlayer
              showWelcomeMessage={false}
              welcomeMessage=""
              onDeleteHandler={clearCurrentVideo}
              mediaBlob={currentVideo}
              src={
                !currentVideo && currentLeadVideoSrc ? currentLeadVideoSrc : ""
              }
            />
          )}
          <RecordingPreview stream={liveStream} />
          <span className="video-description m-t-5">
            Video {index}
            {active &&
              active.fields &&
              ` for ${Object.values(active.fields)[0]}`}
          </span>
          <LeadsVideoList leads={withVideo} className="m-b-30" />
          <ChangeNextLeadVideoBtn
            onNext={onNextLeadHandler}
            leads={leads}
            withVideo={withVideo}
            active={active}
            campaign={campaign}
            currentVideo={currentVideo}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoEachTypeScreen;
